import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Chip, ListItemButton, ListItemText, Typography } from '@mui/material';
import { rgba } from 'polished';
import styled from 'styled-components';
import { ItemType, TitleType } from './types';

export const Item = styled(ListItemButton)<ItemType>`
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  border-radius: 8px;

  svg {
    color: ${(props) => props.theme.sidebar.svgColor};
    font-size: 24px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.hover};
    color: #FFFFFF;
    svg {
      color: ${(props) => props.theme.sidebar.svgColorActive};
    }
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }

  &.${(props) => props.activeclassname} {
    background-color: ${(props) => props.theme.sidebar.activeClass};
    svg {
      color: ${(props) => props.theme.sidebar.svgColorActive};
    }
    span {
      color: ${(props) => props.theme.sidebar.color};
      font-size: 14px;
      padding-right: 8px;
    }
  }
`;

export const Title = styled(ListItemText)<TitleType>`
  margin: 0;

  span {
    color: ${(props) => props.theme.sidebar.text.color};
    font-family: 'Readex Pro';
    font-weight: 600;
    line-height: 20px;
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 0 0 ${(props) => props.theme.spacing(3)};
    max-width: 160px;
    text-transform: capitalize;
  }
`;

export const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

export const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const SectionTitle = styled(Typography)`
  color: ${(props) => props.theme.sidebar.text.color};
  font-family: 'Readex Pro';
  line-height: 20px;
  display: block;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
`;
