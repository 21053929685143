import {CardContent, CircularProgress} from '@mui/material';
import React, {FunctionComponent, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2';
import {errorBigIcon, iconNotifyWarningInfo} from '../../../../assets/icons/icons';
import NoSubscriptionBlock from '../../../../components/Blocks/NoSubscriptionBlock/NoSubscriptionBlock';
import ValidationErrorBlock from '../../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock';
import {Button, Card, FormControl, TextField} from '../../../../components/StyledComponents';
import {EPlatformsNames, ESubscriptionPlans, ETrialStatus} from '../../../../models/consts';
import {IError, IGithubUserAddEditProps} from '../../../../models/inner-models';
import {IGithubUser} from '../../../../models/models';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {notionTrialStatus} from '../../../../store/selectors';
import {decoratePopUpMessage} from '../../../../utils/popUpTextDecorator';
import './style.scss';
import {ExternalLink} from "react-feather";
import Platform from "../../../../models/platforms-data";
import {getNotionLoginUrl} from "../../../../store/actions/platformActions/notionPlatformActions";

const NotionUserAddEdit: FunctionComponent<IGithubUserAddEditProps> = ({
    user,
    subPlan,
    onClose
  }: IGithubUserAddEditProps) => {
    const dispatch = useAppDispatch();
    const { t: translate } = useTranslation();
    const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);
    const trialState = useAppSelector(notionTrialStatus);
    const { formState: { errors }, handleSubmit } = useForm({
      mode: 'onChange',
    });

    const onSubmit: SubmitHandler<IGithubUser> = async () => {
      try {
        setIsUpdatingUser(true);

        if (user?.username) {
          const confirmationChoice = await Swal.fire({
            title: translate('notifications.titles.warning'),
            text: translate('notifications.notion_user.change'),
            confirmButtonText: translate('notifications.choices.continue'),
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: translate('notifications.choices.cancel'),
            showCancelButton: true,
            imageUrl: iconNotifyWarningInfo,
          });

          if (!confirmationChoice.isConfirmed) {
            return;
          }
        }

        const response = await dispatch(user ? getNotionLoginUrl() : getNotionLoginUrl()).unwrap();

        window.open(response.url, '_self');
      } catch (err) {
        const error = err as IError;
        console.log(err);

        Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
        });
      } finally {
        setIsUpdatingUser(false);
      }
    };

  console.log(subPlan?.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED )
  console.log(subPlan?.type , ESubscriptionPlans.NO_SUB , trialState , ETrialStatus.NOT_STARTED )
    return (
      <>
        {subPlan?.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED ?
          <NoSubscriptionBlock platformName={EPlatformsNames.NOTION} />
          :
          <Card mb={6}>
            <CardContent>
              <div className='newapp-step-two-log'>
                <p className='newapp-step-two-title'>
                  Connecting to {Platform.notion.title}
                </p>
                <div className='newapp-step-two-app'>
                  <div className='platformLogoContainer'>
                    <img className="platformLogo" src={Platform.notion.smallLogo} loading="lazy"/>
                  </div>
                  <p className='newapp-step-two-app-title'>
                    {Platform.notion.title}
                  </p>
                </div>
              </div>
              <p className='newapp-step-two-desc'>
                Please authorize BackupLABS to access your {Platform.notion.title} cloud data:
              </p>
              <div id="notion-add-user-description">
                  {user?.username && <div className="form-row">
                    <FormControl className='input-name'>
                      <TextField
                        required
                        id="user-name"
                        name="username"
                        defaultValue={user?.username || ''}
                        error={!!errors.user}
                        disabled={true}
                      />
                      {errors.username &&
                      <ValidationErrorBlock errorMessage={errors.username.message as string} />
                      }
                    </FormControl>
                  </div>
                  }
                  <div className="add-change-user">
                    {onClose && (
                      <Button
                        variant="outlined" color="primary"
                        className='newapp-back'
                        onClick={onClose}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                      color="primary"
                    >
                      {isUpdatingUser &&
                        <div className="small-spinner-wrapper">
                          <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                        </div>
                      }
                      {isUpdatingUser
                        ? translate('notifications.choices.processing')
                        : !user?.username
                          ? translate('forms.notion_add_edit_user.add_new_account')
                          : translate('forms.notion_add_edit_user.edit_upd_account')
                      }
                      <ExternalLink className="feather-icon-in-button icon-ml"/>
                    </Button>
                  </div>
              </div>
            </CardContent>
          </Card>
        }
      </>
    );
  }
;

export default NotionUserAddEdit;
