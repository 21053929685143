import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard, Chip as MuiChip,
  Divider as MuiDivider,
  Drawer as MuiDrawer,
  FormControl as MuiFormControl,
  IconButton as MuiIconButton,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from '@mui/material';
import { styled as muiStyled } from '@mui/styles';
import { spacing } from '@mui/system';
import ReactPerfectScrollbar from 'react-perfect-scrollbar';
import styled, { css } from 'styled-components';

export const IconButton = muiStyled(MuiIconButton)(spacing);
export const Button = muiStyled(MuiButton)(spacing);
export const Divider = muiStyled(MuiDivider)(spacing);
export const Typography = muiStyled(MuiTypography)(spacing);
export const Breadcrumbs = muiStyled(MuiBreadcrumbs)(spacing);
export const Card = muiStyled(MuiCard)(spacing);
export const Paper = muiStyled(MuiPaper)(spacing);
export const FormControl = muiStyled(MuiFormControl)<{ my?: number }>(spacing);
export const TextField = muiStyled(MuiTextField)<{ my?: number }>(spacing);

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const drawerWidth = 258;
export const smallDrawerWidth = 58;

export const DashboardDrawer = styled.div<{bigmenu: string, onClose: () => void}>`
  ${(props) => props.theme.breakpoints.up(901)} {
    width: ${(props) => props.bigmenu ? `${drawerWidth}px` : `${smallDrawerWidth}px`};
    flex-shrink: 0;
    margin-top: 100px;
    transition: all .2s linear;
    + #platform-content {
      width: ${(props) => props.bigmenu ? `calc(100% - ${drawerWidth}px)` : `calc(100% - ${smallDrawerWidth}px)`};
    } 
  }
`;

export const SidebarDrawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    transition: all .2s linear;
    border-right: 0;
    z-index: 99;
  }
`;

const baseScrollbar = css`
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

export const Scrollbar = styled.div`
  ${baseScrollbar}
`;

export const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

export const Items = styled.div`
  padding: ${(props) => props.theme.spacing(2.5)} 8px;
`;

export const AuthWrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(3)};
  min-width: 400px;
  font-family: ${(props) => props.theme.typography.fontFamily};
  z-index: 99;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
  
  @media(max-width: 900px) {
    min-width: 300px;
  }
  
  @media(max-width: 767px) {
    width: 90%;
    min-width: 0 !important;
  }
`;

export const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

export const MyRoot = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

export const Chip = styled(MuiChip)`
  height: 24px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  border-radius: 22px;
  text-transform: capitalize;
  font-size: 14px;
  text-align: center;

  span {
    height: 24px;
  }

  &.unpaid, &.process, &.warning {
    background-color: #FFF3D2;
    color: #FFAF31;
    &.active-process {
      & span {
        padding-right: 8px;
      }
      &::after {
        content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="rotate-ico"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>');
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-bottom: 1px;
        animation: back-rotation 1.5s infinite linear;
        line-height: 14px;
      }
    }
  }

  &.active, &.success {
    background-color: #D6FFE4;
    color: #60C27F;
  }

  &.error {
    background-color: #FFD1C7;
    color: #DB3333;
  }

  &.inactive {
    background-color: #EAECF0;
    color: #6B7280;
  }
`;
