import {Box, Card as MuiCard, CardContent as MuiCardContent} from '@mui/material';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Typography, Chip} from '../../components/StyledComponents';
import {
  errorSubscriptionStatuses,
  EStripeSubStatus,
  inactiveSubscriptionStatuses,
  unpaidSubscriptionStatuses
} from "../../models/consts";

const Card = styled(MuiCard)`
  position: relative;
  padding: 24px;
  margin-bottom: ${(props) => props.theme.spacing(6)};
  color: #344054;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  height: 135px;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;
  padding: 0 !important;
`;

const s = {
  boxPlan: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}

type StatsProps = {
  title: string;
  plan: string;
  status: string;
  finishDate?: string;
};

const SubInfo: React.FC<StatsProps> = ({
                                         title,
                                         plan,
                                         status,
                                         finishDate
                                       }) => {
  const [chipClass, setChipClass] = useState<string>('');

  useEffect(() => {
    let subscriptionStatusClass = 'active';

    if (unpaidSubscriptionStatuses.includes(status as EStripeSubStatus)) {
      subscriptionStatusClass = 'unpaid';
    } else if (errorSubscriptionStatuses.includes(status as EStripeSubStatus)) {
      subscriptionStatusClass = 'error';

    } else if (inactiveSubscriptionStatuses.includes(status as EStripeSubStatus)) {
      subscriptionStatusClass = 'inactive';
    }

    setChipClass(`status ${subscriptionStatusClass}`)
  }, [status])

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" mb={4}>
          {title} Subscriptions
        </Typography>

        <Box sx={s.boxPlan}>
          <Box>
            <div className='dash-subinfo-plan'>
              {plan ?
                plan === '-' ?
                  'N/A' :
                  plan === 'Trial' ?
                    'Free Trial' :
                    `${plan} Plan` :
                'N/A'
              }
            </div>
            <div className='dash-subinfo-plan-date'>{finishDate ? `Until: ${finishDate}` : ''}</div>
          </Box>
          <Chip
            label={status.replaceAll('_', ' ')}
            className={chipClass}/>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubInfo;
