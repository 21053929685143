import {
  CardHeader, CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import {Card, Paper, Typography, Chip} from '../../components/StyledComponents';
import {CustomTooltip} from "../../styles/components/CustomTooltip";
import {HelpCircle} from "react-feather";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
  font-size: 14px;
  line-height: 20px;
  max-height: 480px;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  margin: 0 -1px -1px;

  .MuiTableCell-root {
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
  }

  thead th {
    background: #FAFBFE;
    font-weight: 700;
    font-size: 12px!important;
    text-transform: uppercase;
    padding: 10px 16px;
  }

  tbody th {
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

type TableProps = {
  data?: PropsActivity[];
  loading: boolean;
  title: string;
};

type PropsActivity = {
  id: number | string,
  name: string,
  type: string,
  date: number,
  status: string,
  platform: string,
};

const DashboardTable: React.FC<TableProps> = ({data, loading, title}) => {

  return (
    <Card>
      {loading && (
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div>
      )}
      {!data?.length && !loading &&
        <CardHeader
          title={'Your account has been inactive. Please start a subscription to resume.'}
        />
      }
      {!!data?.length && !loading && (
        <Paper>
          <div className='dashboard-activity-title'>
            <Typography variant="h2" mb={4}>
              {title} Recent Activity
            </Typography>
          </div>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!data?.length && data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {new Date(row.date).toLocaleString()}
                    </TableCell>
                    <TableCell sx={{textTransform: 'capitalize'}}>{row.type}</TableCell>
                    <TableCell>
                      <Chip
                        className={row.status === 'success' ? 'success' : 'error'}
                        label={row.status}
                      />
                      {row.type === 'restore' && row.status !== 'success' && (
                        <CustomTooltip
                          title={`Unable to restore. Check your ${row.platform} account to investigate any limitations.`}>
                          <HelpCircle className="info-icon"/>
                        </CustomTooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </Paper>
      )}
    </Card>
  )
};

export default DashboardTable;
