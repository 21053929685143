import {Card as MuiCard, CardContent as MuiCardContent, CircularProgress} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Typography} from '../../../components/StyledComponents';
import withRedirectAfterLogout from '../../../utils/withRedirectAfterLogout';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';
import ChangePaymentMethodForm from './ChangePaymentMethodForm/ChangePaymentMethodForm';
import './style.scss';
import UserInfoForm from './UserInfoForm/UserInfoForm';
import {removeUsersPaymentTax, updBillingAddress} from "../../../store/actions";
import {IBillingAddressPayload, IError} from "../../../models/inner-models";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {IUser} from "../../../models/models";
import {
  getUserSelector,
  isHasProPlan,
  isSubUser,
  loadingUser,
  roleByApp,
  stripePaymentMethod,
  userOrganizationName
} from "../../../store/selectors";
import styled from 'styled-components';
import BillingForm from "../../../components/BillingForm/BillingForm";
import {errorBigIcon, iconNotifyWarningInfo, toastSuccess} from "../../../assets/icons/icons";
import Swal from "sweetalert2";
import TwoFactorAuthentication from "./TwoFactorAuthentication/TwoFactorAuthentication";
import {decoratePopUpMessage} from "../../../utils/popUpTextDecorator";
import ExternalStorage from "./ExternalStorage/ExternalStorage";
import EmailManage from "./EmailManage/EmailManage";
import InviteUser from "./InviteUser/InviteUser";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};
  border: 1px solid #EAECF0;
  border-radius: 8px;
  width: 100%;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding: 24px 24px 32px 24px;
    box-sizing: border-box;
  }
`;

const SettingPage: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const dispatch = useAppDispatch();
  const userLoading = useAppSelector(loadingUser);
  const user: IUser | null = useAppSelector(getUserSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [billingDet, setBillingDet] = useState<IBillingAddressPayload | null>();
  const defaultPaymentMethod = useAppSelector(stripePaymentMethod);
  const hasProPlan = useAppSelector(isHasProPlan);
  const orgName = useAppSelector(userOrganizationName)
  const isUserSub = useAppSelector(isSubUser);
  const roles = useAppSelector(roleByApp)
  const anyAdminRoles = !!Object.values(roles || {}).filter(i => i === 'admin')?.length
  const [value, setValue] = useState<string>('1');

  const deepEqual = (obj1, obj2) => {
    if (typeof obj1 !== typeof obj2) {
      return false;
    }
    if (obj1 === obj2) {
      return true;
    }
    if (typeof obj1 === 'object') {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (!deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (!userLoading && user && user.company?.address) {
      const billData = {
        company_details: {
          name: user.company.name || '',
          city: user.company.address.city || '',
          country: user.company.address.country || '',
          line1: user.company.address.line1 || '',
          line2: user.company.address.line2 || '',
          state: user.company.address.state || '',
          postal_code: user.company.address.postal_code || ''
        }
      }
      if (!!user.company.tax_details.length) {
        if (user.company.tax_details.length > 1) {
          dispatch(removeUsersPaymentTax(user.company.tax_details[0].id as string))
        }
        const revTax = [...user.company.tax_details].reverse()
        billData['tax_details'] = {
          type: revTax[0].type,
          value: revTax[0].value
        }
      }

      if (!billingDet || billingDet && !deepEqual(billingDet, billData) && !loading) {
        setBillingDet({...billData})
        setLoading(false)
      }
    }
  }, [userLoading, user, loading])

  const updBillAddress = async (newBillingDet) => {
    setLoading(true)
    if (newBillingDet && defaultPaymentMethod) {
      const res = await dispatch(updBillingAddress(newBillingDet)).unwrap()
        .catch((err) => {
          console.log(err)
          const error = err as IError;
          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.message || error.error as string),
            imageUrl: errorBigIcon,
          });
          setLoading(false)
        })
        .finally(() => setLoading(false))
      Swal.fire({
        title: translate('notifications.titles.success'),
        text: 'Billing address was successfully updated',
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000
      });
      return res
    } else {
      Swal.fire({
        title: translate('notifications.titles.warning'),
        text: 'First you need to add a payment method.',
        imageUrl: iconNotifyWarningInfo,
      });
    }
    setLoading(false)
    return true
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div id="settings-page">
      {loading &&
        <div className="spinner-wrapper"><CircularProgress/></div>
      }
      <div className="header-block">
        <Typography variant="h1" gutterBottom display="inline">
          {translate('common.default_pages.profileTitle')}
        </Typography>
      </div>

      <Tabs
        value={value} onChange={handleChange} className='settings-tabs'
        variant="scrollable" scrollButtons="auto"
      >
        <Tab label="User/Company Details" value='1'/>
        <Tab label="Account Security" value='2'/>
        {!isUserSub && (<Tab label="Payment Details" value='3'/>)}
        {(!isUserSub || anyAdminRoles) && (<Tab label="External Storage" value='4'/>)}
        {!isUserSub && (hasProPlan || !!orgName) && (<Tab label="User Management" value='5'/>)}
        <Tab label="Email Reporting" value='6'/>
      </Tabs>

      <Card>
        <CardContent>
          <div className={value !== '1' ? 'settings-block-hide' : ''}>
            <UserInfoForm/>
            {!isUserSub && (
              <BillingForm
                setData={setBillingDet}
                data={billingDet}
                userPage={true}
                upd={updBillAddress}
              />
            )}
          </div>
          <div className={value !== '2' ? 'settings-block-hide' : ''}>
            <ChangePasswordForm/>
            <TwoFactorAuthentication/>
          </div>
          <div className={value !== '3' ? 'settings-block-hide' : ''}>
            {!isUserSub && (
              <ChangePaymentMethodForm/>
            )}
          </div>
          <div className={value !== '4' ? 'settings-block-hide' : ''}>
            {(!isUserSub || anyAdminRoles) && (
              <ExternalStorage/>
            )}
          </div>
          <div className={value !== '5' ? 'settings-block-hide' : ''}>
            {!isUserSub && (hasProPlan || !!orgName) && <InviteUser/>}
          </div>
          <div className={value !== '6' ? 'settings-block-hide' : ''}>
            <EmailManage setLoading={setLoading}/>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default withRedirectAfterLogout()(SettingPage);
