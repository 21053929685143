import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CircularProgress,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {
  getGithubUsersSelector,
  getGitlabUsersSelector,
  getJiraUsersSelector,
  getNotionUsersSelector,
  getRawSubscriptions,
  getTrelloUsersSelector,
  getUserSelector,
  isGithubConnect,
  isGitlabConnect,
  isJiraConnect,
  isNotionConnect,
  isTrelloConnect,
  loadingUser
} from '../../../store/selectors';
import withRedirectAfterLogout from '../../../utils/withRedirectAfterLogout';
import './style.scss';
import styled from 'styled-components';
import {Check} from "react-feather";
import Platform from "../../../models/platforms-data";
import {Button} from "../../../components/StyledComponents";
import {ETrialStatus} from "../../../models/consts";
import {PlatformsState} from "../../../store/reducers/platform";
import {useNavigate, useSearchParams} from "react-router-dom";
import GithubUserAddEdit from "../../PlatformPages/GithubPages/GithubUserAddEditView/GithubUserAddEdit";
import GitlabUserAddEdit from "../../PlatformPages/GitlabPages/GitlabUserAddEditView/GitlabUserAddEdit";
import TrelloUserAddEdit from "../../PlatformPages/TrelloPages/TrelloUserAddEditView/TrelloUserAddEdit";
import JiraUserAddEdit from "../../PlatformPages/JiraPages/JiraUserAddEditView/JiraUserAddEdit";
import NotionUserAddEdit from "../../PlatformPages/NotionPage/NotionUserAddEditView/NotionUserAddEdit";
import {IUser} from "../../../models/models";
import {
  fetchGithubUsers,
  fetchGitlabUsers,
  fetchJiraUser,
  fetchNotionUser,
  fetchTrelloUser,
  getAllSubscriptions,
  updStorageLocation
} from "../../../store/actions";
import {errorBigIcon, iconNotifyWarningInfo, pen, toastSuccess} from "../../../assets/icons/icons";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../utils/popUpTextDecorator";
import {IError} from "../../../models/inner-models";
import {iframeNewTabMsg, iniFrame} from "../../../serviceWorker";
import {BadgesIFrameMode} from "../../../components/Badges/Badges";

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};
  border: 1px solid #EAECF0;
  border-radius: 8px;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding: 0;
    box-sizing: border-box;
  }
`;

type StepType = {
  step: number;
  stepCur?: number;
  title: string;
  desc: string;
}

const Steps = ({step, stepCur, title, desc}: StepType) => {
  return (
    <div
      className={'newapp-step' + (step === stepCur ? ' active-step' : '') + (stepCur && step < stepCur ? ' done-step' : '')}>
      <div className={'newapp-step-icon' + (stepCur && step < stepCur ? ' done-step' : '')}>
        {stepCur && step >= stepCur ?
          '0' + step :
          <Check/>
        }
      </div>
      <div className='newapp-step-text'>
        <div className='newapp-step-title'>
          {title}
        </div>
        <div className='newapp-step-desc'>
          {desc}
        </div>
      </div>
    </div>
  )
}

const AddApp: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userLoading = useAppSelector(loadingUser);
  const user: IUser | null = useAppSelector(getUserSelector);
  const [stepCur, setStepCur] = useState<number>(1);
  const [timeLoader, setTimeLoader] = useState<boolean>(false);
  const [selectedApp, setSelectedApp] = useState<string>('');
  const [dataLoad, setDataLoad] = useState<boolean>(true);
  const [userHandLoad, setUserHandLoad] = useState<boolean>(false);
  const [storeSaving, setStoreSaving] = useState<boolean>(false);
  const [storageLoc, setStorageLoc] = useState<string>('US');
  const usersGithub = useAppSelector(getGithubUsersSelector);
  const usersGitlab = useAppSelector(getGitlabUsersSelector);
  const usersTrello = useAppSelector(getTrelloUsersSelector);
  const usersJira = useAppSelector(getJiraUsersSelector);
  const usersNotion = useAppSelector(getNotionUsersSelector);

  const appGithub = useAppSelector(isGithubConnect);
  const appGitlab = useAppSelector(isGitlabConnect);
  const appTrello = useAppSelector(isTrelloConnect);
  const appJira = useAppSelector(isJiraConnect);
  const appNotion = useAppSelector(isNotionConnect);

  const platform: PlatformsState | null = useAppSelector(getRawSubscriptions);
  const [activeSub, setActiveSub] = useState<Record<string, string>>({
    github: '',
    trello: '',
    gitlab: '',
    jira: '',
    notion: '',
  });
  const [searchParams] = useSearchParams();

  const storagePlace = {
    DE: 'Europe (Germany)',
    UK: 'Europe (United Kingdom)',
    US: 'North America (United States)'
  }

  useEffect(() => {
    const errorMessage = searchParams.get('error_message');
    const warningMessage = searchParams.get('warning_message');
    const confirmGithub = searchParams.get('githubConfirmStatus');
    const iframeJira = searchParams.get('jiraInIframe');

    if (confirmGithub !== null && confirmGithub === 'false') {
      setTimeLoader(true)
      setUserHandLoad(true)
      setStepCur(3);
      setSelectedApp('github')
    }
    if (iframeJira !== null && iframeJira === 'true') {
      setUserHandLoad(true)
      setStorageLoc('')
      setStepCur(2);
      setSelectedApp('jira')
    }

    if (!errorMessage && !warningMessage) {
      return
    }

    Swal.fire({
      imageUrl: errorMessage ? errorBigIcon : iconNotifyWarningInfo,
      title: errorMessage ? translate('notifications.titles.error') : translate('notifications.titles.warning'),
      text: errorMessage ? decoratePopUpMessage(translate('notifications.error_during_connection', {err: errorMessage})) : decoratePopUpMessage(warningMessage as string),
    });
  }, []);

  const connectApps = {
    github: appGithub,
    trello: appTrello,
    gitlab: appGitlab,
    jira: appJira,
    notion: appNotion,
  }

  const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  useEffect(() => {
    const fetchData = async () => {
      if (stepCur === 3 && selectedApp && (user?.hadEverAnySubscriptions || selectedApp === 'github')) {
        switch (selectedApp) {
          case 'github':
            await delay(timeLoader ? 10000 : 0)
            await dispatch(fetchGithubUsers()).unwrap().catch(err => console.log(err))
            if (usersGithub) setUserHandLoad(false)
            setTimeLoader(false)
            break;
          case 'gitlab':
            if (usersGitlab) setUserHandLoad(false)
            await dispatch(fetchGitlabUsers()).unwrap().catch(err => console.log(err))
            break;
          case 'trello':
            if (usersTrello) setUserHandLoad(false)
            await dispatch(fetchTrelloUser()).unwrap().catch(err => console.log(err))
            break;
          case 'jira':
            if (usersJira) setUserHandLoad(false)
            await dispatch(fetchJiraUser()).unwrap().catch(err => console.log(err))
            break;
          case 'notion':
            if (usersNotion) setUserHandLoad(false)
            await dispatch(fetchNotionUser()).unwrap().catch(err => console.log(err))
            break;
          default:
            break
        }
        setUserHandLoad(false)
      } else if (!user?.hadEverAnySubscriptions && selectedApp !== 'github' && searchParams.get('githubConfirmStatus') !== 'false') {
        setUserHandLoad(false)
      }
    }
    fetchData();
  }, [stepCur, selectedApp])

  useEffect(() => {
    if (platform && !userLoading && !Object.values(platform)?.filter(i => !i.trial)?.length) {
      Object.keys(platform).forEach(item => {
        if ((!!platform[item].subscription && platform[item].subscription.status === 'active') ||
          platform[item].trial && platform[item].trial.trialStatus === ETrialStatus.STARTED) {
          setActiveSub(prevState => {
            return {
              ...prevState,
              [item]: 'active'
            }
          })
        } else if ((!!platform[item].subscription && platform[item].subscription.status !== 'active') ||
          platform[item].trial && platform[item].trial.trialStatus === ETrialStatus.EXPIRED) {
          setActiveSub(prevState => {
            return {
              ...prevState,
              [item]: 'inactive'
            }
          })
        }
      })
      setDataLoad(false)
    }
  }, [platform, userLoading])

  const stepsList: StepType[] = [
    {
      step: 1,
      title: 'Select Application',
      desc: 'Which application would you like to connect?'
    },
    {
      step: 2,
      title: 'Data Residency',
      desc: 'Select the region to store your backups.'
    },
    {
      step: 3,
      title: 'Authorize BackupLABS',
      desc: 'Authorize us so we can access your cloud data.'
    }
  ];

  const product = (prod, name, active) => {
    return (
      <div key={name} className="newapp-item-wrapper">
        <div className='platformLogoContainer'>
          <img className="platformLogo" src={prod.smallLogo} loading="lazy"/>
        </div>
        {active[name] &&
          <span className={'newapp-badge newapp-badge--' + active[name]}>
            {active[name]}
          </span>
        }
        <p className='newapp-item-title'>
          {prod.title}
        </p>
        <p className='newapp-item-desc'>
          {translate(`views.new_app.list.${name}`)}
        </p>
        <Button variant="contained" color={active[name] && connectApps[name] ? "secondary" : "primary"}
                className='newapp-item-btn'
                disabled={Object.keys(active).indexOf(name) === -1}
                onClick={() => {
                  if (!active[name] || !connectApps[name] || active[name] === 'active') {
                    if (iniFrame()) {
                      iframeNewTabMsg()
                    } else {
                      if (platform[name].trial.trialStatus === 'not started') {
                        setStorageLoc('')
                      } else {
                        setStorageLoc(platform[name].regionS3)
                      }
                      setUserHandLoad(true)
                      setStepCur(2);
                      setSelectedApp(name)
                    }
                    // } else if (active[name] === 'active') {
                    //   navigate(`/platform/dashboard/${name}`)
                  } else if (active[name] === 'inactive') {
                    localStorage.setItem('checkout-platform', name)
                    navigate('/checkout');
                  }
                }}
        >
          {Object.keys(active).indexOf(name) === -1 ?
            translate('appConnectors.coming') :
            (active[name] === 'inactive' && connectApps[name]) ?
              translate('notifications.choices.buy_subscription') :
              (active[name] === 'active' && connectApps[name]) ?
                <>
                  <img src={pen} loading="lazy"/>
                  {translate('views.new_app.btnEditConnect')}
                </> :
                translate('views.new_app.btnConnect')
          }
        </Button>
      </div>
    )
  };

  const saveLocation = async () => {
    try {
      setStoreSaving(true)
      let allowUpd = true
      if (platform[selectedApp].trial.trialStatus !== 'not started') {
        await Swal.fire({
          title: translate('notifications.titles.warning'),
          text: `${platform[selectedApp].regionS3 !== storageLoc ? 'Changing the location will result in losing your backups and restarting.' : ''}`,
          imageUrl: iconNotifyWarningInfo,
          confirmButtonText: translate('notifications.choices.confirm'),
          cancelButtonText: translate('notifications.choices.cancel'),
          showCancelButton: true,
          showLoaderOnConfirm: true,
        })
          .then(res => {
            if (!res.isConfirmed) {
              allowUpd = false
            }
          })
      }

      if (!allowUpd) {
        setStoreSaving(false)
        return
      }
      if (platform[selectedApp].regionS3 !== storageLoc) {
        await dispatch(updStorageLocation({
          platform: selectedApp,
          region: storageLoc
        })).unwrap()
        Swal.fire({
          title: translate('notifications.titles.success'),
          text: 'Your data location has been saved',
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
          imageUrl: toastSuccess,
          timer: 3000,
        });
        dispatch(getAllSubscriptions()).unwrap()
      }
      setStepCur(3)
      setStoreSaving(false)
    } catch (err) {
      const error = err as IError;
      setStoreSaving(false)
      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  return (
    <div id="newapp-page" className={!user?.hadEverAnySubscriptions ? 'container-view' : ''}>
      {userLoading && platform && dataLoad && !!Object.values(platform)?.filter(i => !i.trial)?.length ?
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div> :
        <div className="newapp-block">
          {!user?.hadEverAnySubscriptions ?
            <>
              <Typography variant="h1" gutterBottom display="block" marginBottom={8}>
                Welcome, {user?.name || 'User'}
              </Typography>
              <Typography variant="h4" gutterBottom display="block">
                {translate('appConnectors.subTitle')}
              </Typography>
            </> :
            <Typography variant="h1" display="block" mb='32px'>
              {translate('common.default_pages.app_connector')}
            </Typography>
          }
          {iniFrame() &&
            <BadgesIFrameMode/>
          }
          <div className='newapp-actions'>
            {stepsList.map(item => {
              return (
                <Steps key={item.step} step={item.step} stepCur={stepCur} title={item.title} desc={item.desc}/>
              )
            })}
          </div>

          <Typography variant="h4" display="block" mb='20px' lineHeight='32px'>
            {stepsList[stepCur - 1].title}
          </Typography>

          {stepCur === 1 && (
            <div className='newapp-list-container'>
              {Object.keys(Platform)
                .map(item => {
                  return product(Platform[item], item, activeSub)
                })
              }
            </div>
          )}
          {stepCur === 2 && (
            <Card>
              <CardContent>
                <div className='newapp-storage'>
                  <p className='selected-app-title'>
                    Storage location for {Platform[selectedApp].title}
                  </p>
                  <div className='newapp-storage-sub-text'>
                    Please choose the region you would like us to store and process your data:
                  </div>
                  <Select
                    displayEmpty
                    value={storageLoc}
                    className={!storageLoc ? 'github-org-select github-org-select--disabled ' : 'github-org-select'}
                    MenuProps={{id: 'sub-github-select'}}
                  >
                    <MenuItem value='' key='dis' disabled>
                      Select region...
                    </MenuItem>
                    {Object.keys(storagePlace).map(pl => {
                      return (
                        <MenuItem
                          key={pl}
                          value={pl}
                          onClick={() => {
                            setStorageLoc(pl as string)
                          }}
                        >
                          {storagePlace[pl]}
                        </MenuItem>
                      )
                    })}
                  </Select>

                  <div className="action-buttons">
                    <Button
                      variant="outlined" color="primary" mr={3}
                      onClick={() => setStepCur(1)}
                    >
                      {translate('common.buttons.back')}
                    </Button>
                    {platform[selectedApp].trial.trialStatus !== 'not started' && (
                      <Button
                        variant="contained" color="secondary" mr={3}
                        disabled={storeSaving}
                        onClick={() => {
                          if (platform[selectedApp].regionS3 !== storageLoc) {
                            setStorageLoc(platform[selectedApp].regionS3)
                          }
                          setStepCur(3)
                        }}
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                      variant="contained" color="primary" mr={3}
                      disabled={storeSaving || (platform[selectedApp].regionS3 === storageLoc && platform[selectedApp].trial.trialStatus !== 'not started') || !storageLoc}
                      onClick={() => {
                        saveLocation()
                      }}
                    >
                      {storeSaving && (
                        <div className="small-spinner-wrapper">
                          <CircularProgress color="inherit" style={{width: '20px', height: '20px', marginTop: '6px'}}/>
                        </div>
                      )}
                      Save
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
          {stepCur === 3 && (
            <Card>
              <CardContent>
                {userHandLoad ?
                  <div className="spinner-wrapper">
                    <CircularProgress/>
                    {timeLoader && (
                      <div className='spinner-text'>Please wait a few seconds.<br/> We are getting the information
                        from {Platform[selectedApp].title}.</div>
                    )}
                  </div> :
                  <div className='newapp-step-two'>
                    {
                      selectedApp === 'jira' ?
                        <JiraUserAddEdit
                          onClose={() => setStepCur(prevState => prevState - 1)}
                          user={usersJira?.[0]}
                        /> :
                        selectedApp === 'trello' ?
                          <TrelloUserAddEdit
                            onClose={() => setStepCur(prevState => prevState - 1)}
                            user={usersTrello?.[0]}
                          /> :
                          selectedApp === 'gitlab' ?
                            <GitlabUserAddEdit
                              onClose={() => setStepCur(prevState => prevState - 1)}
                              user={usersGitlab?.[0]}
                            /> :
                            selectedApp === 'notion' ?
                              <NotionUserAddEdit
                                onClose={() => setStepCur(prevState => prevState - 1)}
                                user={usersNotion?.[0]}
                              /> :
                              <GithubUserAddEdit
                                onClose={() => setStepCur(prevState => prevState - 1)}
                                user={usersGithub ? usersGithub?.filter(i => i.userType === 'User')?.[0] || usersGithub[0] : {}}
                              />
                    }
                  </div>
                }
              </CardContent>
            </Card>
          )}
        </div>
      }
    </div>
  );
};

export default withRedirectAfterLogout()(AddApp);
