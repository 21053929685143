import './style.scss';
import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, FormControl, TextField} from "../../../StyledComponents";
import {RefreshCcw} from "react-feather";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {
  EPlatformsNames,
  JiraProjectsTypeKey,
  MAX_INPUT_VALUE_EMAIL_LENGTH,
  validateEmails
} from "../../../../models/consts";
import ValidationErrorBlock from "../../../Blocks/ValidationErrorBlock/ValidationErrorBlock";
import {Checkbox} from "@mui/material";
import {CheckboxInputChecked, CheckboxInputEmpty} from "../../../../assets/icons/icons";
import {BadgesJiraError} from "../../../Badges/Badges";
import Autocomplete from '@mui/material/Autocomplete';
import {CustomTooltip} from '../../../../styles/components/CustomTooltip';
import {IGithubRestorePlace} from "../../../../models/models";

interface IRestoreModalProps {
  platformName: string;
  name: string;
  id: string;
  backupVer: string;
  date: string;
  isGist: boolean;
  close: () => void;
  restore: (backupVer: string, newName: string, id: string, emails: string[], organizationId?: string, place?:IGithubRestorePlace) => void;
  jiraRestore?: Array<{ id: string; name: string; }>
  projectTypeKey: string | null;
  trelloRestoreTo: { id: string, name: string }[];
  githubRestoreTo: IGithubRestorePlace[]
  canRestore: boolean;
}

interface IRestoreForm {
  name: string;
  newName: string;
  backupVer: string;
  emails: string;
  organizationId: string
  place?: IGithubRestorePlace
}

const RestoreModal: FunctionComponent<IRestoreModalProps> = ({
                                                               close,
                                                               restore,
                                                               platformName,
                                                               name,
                                                               backupVer,
                                                               id,
                                                               date,
                                                               isGist,
                                                               jiraRestore,
                                                               projectTypeKey,
                                                               trelloRestoreTo,
                                                               canRestore,
                                                               githubRestoreTo
                                                             }) => {
  const {t: translate} = useTranslation();
  const {control, register, trigger, formState: {errors, isValid}, handleSubmit, watch} = useForm<IRestoreForm>({
    mode: 'onChange',
  });
  const newNameField = watch('newName')
  const organizationId = watch('organizationId')
  const place = watch('place')
  const NEWNAME_REGEX = /^[a-zA-Z0-9](?!.*[ .+_-]{2,})(?!.*\s{2,})[a-zA-Z0-9_ .+-]*[a-zA-Z0-9]+$/
  // /^[a-zA-Z0-9_](?!.*[.+_-]{2,})(?!.*\s{2,})(?!(.* [.+_-]{1}){2,})(?!(.*[.+_-]{1} [.+_-]{1}){1})[a-zA-Z0-9_ .+-]+$/;
  const newNamePattern = {
    value: NEWNAME_REGEX,
    message: 'Name must start with a letter or number. Names cannot contain spaces, dots, dashes, or special characters.',
  }

  useEffect(() => {
    trigger('newName')
    if (newNameField) {
      localStorage['jiraNewName'] = jiraRestore?.find(i => i.id === newNameField)?.name
    }
  }, [newNameField])

  const onSubmit: SubmitHandler<IRestoreForm> = (data) => {
    const newName = !!data.newName?.length ? data.newName : (name + '_restored')
    let emailArr: string[] = []
    if (!!data.emails?.replaceAll(' ', '').length) {
      emailArr = data.emails.replaceAll(' ', '').split(',')
    }
    restore(backupVer, newName, id, emailArr, organizationId , data.place)
    close()
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className='restore-modal'>
      <div className='restore-content'>
        {platformName !== 'jira' ?
          <p className='restore-content-line-top'>
            {translate(`forms.restoreModal.${isGist ? platformName + '_sub' : platformName}.p1`)}
            {platformName === 'gitlab' && !isGist && (
              <><br/><br/><b>Attention</b> - Any snippets inside will be duplicated both inside the new project and as a snippet.</>
            )}
          </p> :
          platformName === 'jira' ?
            (newNameField !== 'more' ?
              <p className='restore-content-line-top'>
                {translate(`forms.restoreModal.jira.p1`)}
              </p> :
              <p className='restore-content-line-top'>Select a project to restore to</p>) :
            <p className='restore-content-line-top'/>
        }
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {platformName === 'jira' && jiraRestore?.find(i => i.id === newNameField)?.name === name && (
            <BadgesJiraError platformName={platformName} isModal={true}/>
          )}
          <FormControl className="form-row">
            <div className="label-block">
              {isGist ? (platformName === 'github' ? 'Gist name' : 'New snippet name') :
                capitalizeFirstLetter(translate('forms.restoreModal.name', {item: translate(`views.bulk_restore.itemName.${platformName}.one`)}))
              }
            </div>
            <TextField
              disabled
              value={name}
              placeholder={translate('forms.restoreModal.name')}
              className=''
              id="name"
              type="text"
              inputProps={{maxLength: MAX_INPUT_VALUE_EMAIL_LENGTH}}
              error={!!errors.name}
            />
            {errors.name &&
                <ValidationErrorBlock errorMessage={errors.name.message as string}/>
            }
          </FormControl>
          <FormControl className="form-row">
            <div className="label-block">{translate('forms.restoreModal.verId')}</div>
            <TextField
              disabled
              value={date}
              placeholder={translate('forms.restoreModal.verId')}
              className=''
              id="backupVer"
              type="text"
              inputProps={{maxLength: MAX_INPUT_VALUE_EMAIL_LENGTH}}
              error={!!errors.backupVer}
            />
            {errors.backupVer &&
                <ValidationErrorBlock errorMessage={errors.backupVer.message as string}/>
            }
          </FormControl>

          <FormControl className="form-row">
            <div className="label-block">
              {platformName === 'jira' ? translate('forms.restoreModal.restoreTo') :
                isGist ? (platformName === 'github' ? 'New gist name' : 'New snippet name') :
                  translate('forms.restoreModal.newName', {item: translate(`views.bulk_restore.itemName.${platformName}.one`)})
              }
            </div>
            {platformName !== 'jira' ?
              <TextField
                placeholder={translate('forms.restoreModal.newName', {item: translate(`views.bulk_restore.itemName.${platformName}.one`)})}
                className=''
                disabled={isGist && platformName === 'github'}
                defaultValue={isGist && platformName === 'github' ? 'N/A' : `${name}_restored`}
                id="newName"
                type="text"
                inputProps={{
                  maxLength: MAX_INPUT_VALUE_EMAIL_LENGTH,
                }}
                {...register('newName', {
                  maxLength: MAX_INPUT_VALUE_EMAIL_LENGTH,
                  pattern: platformName === 'gitlab' ? newNamePattern : undefined
                })}
                error={!!errors.newName}
              /> :
              <Controller
                name='newName'
                control={control}
                render={({field: {onChange, value}}) => (
                  <Autocomplete
                    ListboxProps={{id: 'restore-select'}}
                    className='restore-item-select-ver jira-select'
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => {
                      return option?.label
                    }}
                    onChange={(event, item) => {
                      onChange(item.id);
                    }}
                    disableClearable={true}
                    value={value}
                    options={
                      [...(jiraRestore ? jiraRestore.map((item) => {
                        return (
                          {id: item.id, label: name === item.name ? (item.name + ' ⚠') : item.name}
                        )
                      }) : [])].filter(Boolean)
                    }
                    renderInput={(params) => <TextField {...params} placeholder='Search project'/>}
                  />
                )}
              />
            }
            {errors.newName &&
                <ValidationErrorBlock errorMessage={errors.newName.message as string}/>
            }
            {((!isGist && platformName !== 'jira') || platformName === 'gitlab') && (
              <div className='row-sub-right'>Optional</div>
            )}
          </FormControl>

          {platformName === EPlatformsNames.TRELLO && trelloRestoreTo && !!trelloRestoreTo.length  &&
            <FormControl className="form-row">
              <div className="label-block">
                {translate('forms.restoreModal.restoreToWorkspace')}
              </div>
              {
                <Controller
                  name='organizationId'
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Autocomplete
                      ListboxProps={{id: 'restore-workspace-select'}}
                      className='restore-item-select-ver jira-select'
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={option => {
                        return option?.label
                      }}
                      onChange={(event, item) => {
                        onChange(item.id);
                      }}
                      disableClearable={true}
                      value={value}
                      options={
                        [...(trelloRestoreTo ? trelloRestoreTo.map((item) => {
                          return (
                            {id: item.id, label: item.name}
                          )
                        }) : [])].filter(Boolean)
                      }
                      renderInput={(params) => <TextField {...params} placeholder='Search workspace'/>}
                    />
                  )}
                />
              }
              {errors.organizationId &&
                  <ValidationErrorBlock errorMessage={errors.organizationId.message as string}/>
              }
            </FormControl> }

          {platformName === EPlatformsNames.GITHUB && githubRestoreTo && !!githubRestoreTo.length &&
            <FormControl className="form-row">
              <div className="label-block">
                {translate('forms.restoreModal.restoreToPlace')}
              </div>
              {
                <Controller
                  name='place'
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <Autocomplete
                      ListboxProps={{id: 'restore-place-select'}}
                      className='restore-item-select-ver jira-select'
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      getOptionLabel={option => {
                        return option?.label
                      }}
                      onChange={(event, item) => {
                        if(!item) return
                        onChange({id:item.id,name:item.label,node_id:item.node_id,type:item.type});
                      }}
                      value={value}
                      options={
                        [...(githubRestoreTo ? githubRestoreTo.map((item) => {
                          return (
                            {id: item.id, label: item.name, node_id:item.node_id,type:item.type}
                          )
                        }) : [])].filter(Boolean)
                      }
                      renderInput={(params) => <TextField {...params} placeholder='Search place'/>}
                    />
                  )}
                />
              }
              {errors.place &&
                  <ValidationErrorBlock errorMessage={errors.place.message as string}/>
              }
            </FormControl>}
          <FormControl className="form-row">
            <div className="label-block">{translate('forms.restoreModal.sendEmail')}</div>
            <TextField
              placeholder={translate('forms.restoreModal.sendEmail')}
              className=''
              id="emails"
              type="text"
              {...register('emails', {
                validate: validateEmails
              })}
              error={!!errors.emails}
            />
            {errors.emails &&
                <ValidationErrorBlock errorMessage={errors.emails.message as string}/>
            }
            <small>
              {translate('forms.restoreModal.sendSub')}
            </small>
          </FormControl>
          <div className="action-buttons">
            <Button variant="outlined" color="primary" onClick={close}>
              {translate('common.buttons.cancel')}
            </Button>
            <CustomTooltip title={!canRestore ? 'Please wait until another element has completed its action.' : ''}>
              <Button
                className="restore-btn" variant="contained"
                color="primary" type='submit'
                disabled={!canRestore || (platformName === EPlatformsNames.TRELLO && (!organizationId) && !!trelloRestoreTo.length)  || (platformName === EPlatformsNames.GITHUB && (!place) && !!githubRestoreTo.length)  || (platformName === EPlatformsNames.JIRA && (!newNameField || newNameField === 'more')) || !isValid}
              >
                {projectTypeKey !== JiraProjectsTypeKey.software && (
                  <RefreshCcw className='feather-icon-in-button'/>
                )}
                {projectTypeKey !== JiraProjectsTypeKey.software ? translate('common.buttons.restore') : 'Match Columns'}
              </Button>
            </CustomTooltip>
          </div>
        </form>
      </div>
    </div>
  )
}

export default (RestoreModal)
