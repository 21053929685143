import {Card as MuiCard, CardContent as MuiCardContent, Grid} from '@mui/material';
import React, {useState} from 'react';
import styled from 'styled-components';
import {RefreshCcw} from "react-feather";
import {awsS3, dbIco, dropbox, gDrive, iconNotifyAlert, successIcon} from "../../assets/icons/icons";
import {useAppSelector} from "../../store/hooks";
import {connectedExtStorage} from "../../store/selectors";
import ExternalStorage from "../UserPages/UserSettings/ExternalStorage/ExternalStorage";
import Modal from "../../components/Dialogs/Modal/Modal";

const Card = styled(MuiCard)`
  position: relative;
  padding: 24px 32px;
  margin-bottom: ${(props) => props.theme.spacing(2)};
  color: #344054;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  margin-top: 24px;
  text-transform: capitalize;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;
  padding: 0 !important;
`;

type InfoProps = {
  lastBackup: string;
  total: number;
};

const storageList = {
  "dropbox": { title: 'Dropbox', icon: dropbox },
  "google_drive": { title: 'Google Drive', icon: gDrive },
  "s3": { title: 'AWS S3', icon: awsS3 }
}

const DashInfoCard: React.FC<InfoProps> = ({lastBackup, total}) => {
  const external = useAppSelector(connectedExtStorage)?.[0];
  const [open, setOpen] = useState<boolean>(false);


  return (
    <Grid container spacing={6} className='dashboard-info-cards'>
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card className='violet-card'>
          <CardContent>
            <div className='info-cards-ico'>
              <img src={dbIco} className="feather-icon-in-button" loading="lazy"/>
            </div>
            <div className='info-cards-block'>
                <div className='info-cards-title'>Total protected objects</div>
                <div className='info-cards-data'>{total ? total : '-'}</div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card className='green-card'>
          <CardContent>
            <div className='info-cards-ico'>
              <img src={successIcon} className="feather-icon-in-button" loading="lazy"/>
            </div>
            <div className='info-cards-block'>
              <div className='info-cards-title'>Status</div>
              <div className='info-cards-data'>Service Online</div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card className='yellow-card'>
          <CardContent>
            <div className='info-cards-ico'>
              <RefreshCcw className="feather-icon-in-button"/>
            </div>
            <div className='info-cards-block'>
              <div className='info-cards-title'>Last Backup</div>
              <div className='info-cards-data'>{lastBackup ? lastBackup : '-'}</div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
        <Card className='blue-card' onClick={() => {setOpen(true)}}>
          <CardContent>
            {external ?
              <div className='info-cards-status synced'><div/>Synced</div> :
              <div className='info-cards-status not-synced'><div/>Disconnected</div>
            }
            <div className='info-cards-ico'>
              {external ?
                <img src={storageList[external]?.icon} loading="lazy"/> :
                <img src={iconNotifyAlert} loading="lazy"/>
              }
            </div>
            <div className='info-cards-block'>
              <div className='info-cards-title'>External Storage</div>
              <div className='info-cards-data'>
                {external ? storageList[external]?.title : 'Not connected'}
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Modal
        isOpen={open}
        className='ext-storage-modal'
        closeNotification={() => setOpen(false)}
        child={<ExternalStorage
          platformModal='trello'
          closeModal={() => setOpen(false)}
        />}
      />
    </Grid>
  );
};

export default DashInfoCard;
