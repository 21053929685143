import './style.scss';
import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../../components/StyledComponents";
import {CircularProgress, RadioGroup} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {IError} from "../../../../../models/inner-models";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../../utils/popUpTextDecorator";
import {errorBigIcon} from "../../../../../assets/icons/icons";
import {useAppDispatch} from "../../../../../store/hooks";
import {updateUserWorkspace} from "../../../../../store/actions";
import RadioButton from "../../../../../components/Buttons/RadioButton";
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles({
    spinner: {
      color: '#319CFF',
    },
  },
);

interface IJiraWorkspaceModalProps {
  data: Array<string>;
  close: () => void;
}

const JiraWorkspaceList: FunctionComponent<IJiraWorkspaceModalProps> = ({close, data}) => {
  const {t: translate} = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const onSubmit = async () => {
    try {
      setIsUpdatingUser(true)
      await dispatch(updateUserWorkspace(name)).unwrap()
      setIsUpdatingUser(false)
      navigate(window.location.pathname, { replace: true })
      window.location.reload();
      close()
    } catch (e) {
      const error = e as IError;

      setIsUpdatingUser(false)
      return Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
      });
    }
  }

  return (
    <div className='jira-workspace-modal'>
      {!data?.length ?
        <div className="spinner-wrapper">
          <CircularProgress className={classes.spinner}/>
        </div> :
        <div className='restore-content'>
          <p className='restore-content-line-top'>
            You need to select the Jira workspace you want to backup.
          </p>

          <RadioGroup onChange={(e)=>setName(e.target.value as string)}>
            {data?.map(item => {
              return (
                <div key={item} className='jira-match-row'>
                  <RadioButton key={item} checked={item === name}
                               value={item}
                               name={item + "-radio"}
                  />
                  {item}
                </div>
              )
            })}
          </RadioGroup>


          <div className="action-buttons">
            <Button variant="outlined" color="primary" onClick={close} title='If you cancel this step, you will have to start connecting the jira from the beginning.'>
              {translate('common.buttons.cancel')}
            </Button>
            <Button className="restore-btn" variant="contained" color="primary" onClick={onSubmit} disabled={!name || isUpdatingUser}>
              {isUpdatingUser &&
                <div className="small-spinner-wrapper">
                  <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
                </div>
              }
              Confirm
            </Button>
          </div>
        </div>
      }
    </div>
  )
}

export default (JiraWorkspaceList)
