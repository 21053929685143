const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        fontWeight: '500',
        fontSize: '14px',
        padding: '0 12px',
        minHeight: '36px',
        minWidth: '36px',
        height: '38px',
        textTransform: 'capitalize',
      }
    }
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&.MuiButton-root:disabled': {
          backgroundColor: '#D0D5DD!important',
          color: '#ffffff!important'
        },
        '&.MuiButton-outlined:not(.MuiButton-outlinedError)' : {
          border: '1px solid #D0D5DD',
          backgroundColor: '#fff',
          color: '#344054',
          '&:hover': {
            backgroundColor: '#DCF0FF',
          }
        },
        '&.MuiButton-outlinedError': {
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: '#FFD1C7',
          }
        },
        '&.MuiButton-outlinedWarning': {
          backgroundColor: '#fff',
          border: '1px solid #DB3333',
          color: '#DB3333',
          '&:hover': {
            backgroundColor: '#FFD1C7',
          }
        },
        '&.MuiButton-containedSecondary': {
          '&:hover': {
            color: '#FFFFFF'
          }
        }
      },
    }
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
        border: '1px solid #EAECF0',
        '& .MuiDataGrid-main': {
          borderBottom: '1px solid #E5E7EB!important'
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '.MuiDataGrid-row:hover': {
          backgroundColor: '#EAF7FFa1',
        },
        '&  .MuiDataGrid-columnHeaders': {
          backgroundColor: '#FAFBFE',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          fontSize: '12px',
          textTransform: 'uppercase',
          color: '#6B7280',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '700!important',
          }
        },
        '& .MuiDataGrid-cell': {
          paddingLeft: '16px',
          boxSizing: 'border-box',
          color: '#6B7280'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none'
        },
        '.MuiDataGrid-overlay': {
          zIndex: 99,
          height:'100%!important'
        }
      }
    }
  },
  MuiPagination: {
    styleOverrides: {
      root: {
        '& .MuiPaginationItem-root': {
          boxSizing: 'border-box',
          fontWeight: 500,
          width: '34px',
          height: '34px',
          borderRadius: '40px',
          '&:hover:not(.Mui-selected, .MuiPaginationItem-ellipsis)': {
            color: '#ffffff',
            backgroundColor: '#319CFF'
          },
        },
        '& .Mui-selected': {
          backgroundColor: '#0283FA!important',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#0283FA'
          }
        },
        '& .MuiPaginationItem-ellipsis': {
          lineHeight: '32px',
          cursor: 'default'
        }
      }
    }
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '& .MuiInputLabel-formControl[data-shrink|="false"]': {
          transform: 'translate(14px, 10px) scale(1)'
        }
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        height: '42px',
        lineHeight: '40px',
        background: '#FFFFFF',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        borderRadius: '6px',
        '&.MuiInputBase-multiline': {
          height: 'auto'
        }
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {

      root: {
        borderRadius: '10px',
        border: '1px solid #F3F4F6',
        '& .MuiAutocomplete-root': {
          height: '40px',
          borderRadius: '10px',
          border: '1px solid #F3F4F6',
        },
        '& .MuiAutocomplete-inputRoot': {
          paddingTop: '2px!important',
          height: '38px'
        },
        '& .MuiAutocomplete-endAdornment': {
          top: '-2px!important'
        }
      }
    }
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        backgroundImage: "none",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
};

export default components;
