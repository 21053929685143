import React, {FunctionComponent, useEffect, useState} from 'react';
import './style.scss';
import {Button, Chip} from "../../../../components/StyledComponents";
import Swal from "sweetalert2";
import {
  awsS3,
  CheckboxInputChecked,
  CheckboxInputEmpty,
  dropbox,
  errorBigIcon,
  gDrive,
  toastSuccess
} from "../../../../assets/icons/icons";
import {useTranslation} from "react-i18next";
import Platform from "../../../../models/platforms-data";
import {Checkbox, CircularProgress, Typography} from "@mui/material";
import {CloudStoragePlatform, IError} from "../../../../models/inner-models";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {getCloudStorageUrl, getUser, removeCloudStorage, setCloudStorage} from "../../../../store/actions";
import {decoratePopUpMessage} from "../../../../utils/popUpTextDecorator";
import {PlatformsState} from "../../../../store/reducers/platform";
import {connectedExtStorage, getRawSubscriptions, isSubUser, roleByApp} from "../../../../store/selectors";
import {EGithubPlanNames} from "../../../../models/paymentPlans/github-payments-plan";
import {updateSubscriptionPlanModal} from "../../../../utils/functions/updateSubscriptionPlanModal";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ETrialStatus} from "../../../../models/consts";
import {CustomTooltip} from "../../../../styles/components/CustomTooltip";
import {iframeNewTabMsg, iniFrame} from "../../../../serviceWorker";
import AWSS3Tutorial from "./AWSS3External/AWSS3Tutorial";
import {Info} from "react-feather";
import AWSS3Connection from "./AWSS3External/AWSS3Connection";

interface IExtProps {
  platformModal?: string;
  closeModal?: () => void;
}

const ExternalStorage: FunctionComponent<IExtProps> = ({platformModal, closeModal}) => {
  const {t: translate} = useTranslation();
  const dispatch = useAppDispatch();
  const connectedStorage = useAppSelector(connectedExtStorage);
  const [curData, setCurData] = useState<CloudStoragePlatform>({})
  const [newData, setNewData] = useState<CloudStoragePlatform>({})
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const platform = Platform;
  const platformSub: PlatformsState | null = useAppSelector(getRawSubscriptions);
  const isUserSub = useAppSelector(isSubUser);
  const roles = useAppSelector(roleByApp)
  const [searchParams] = useSearchParams();
  const [isOpenTutorial, setIsOpenTutorial] = useState<boolean>(false);
  const [isOpenAWSConnect, setIsOpenAWSConnect] = useState<boolean>(false);

  const storageList = [
    {title: 'Dropbox', tag: 'dropbox', icon: dropbox},
    {title: 'Google Drive', tag: 'google_drive', icon: gDrive},
    {title: 'AWS S3', tag: 's3', icon: awsS3}
  ]
  const navigate = useNavigate();

  useEffect(() => {
    const storageConnected = searchParams.get('isCloudStorageConnected');
    if (storageConnected === 'true') {
      Swal.fire({
        title: translate('notifications.titles.success'),
        text: 'Storage service successfully connected',
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
      navigate(window.location.pathname, {replace: true})
    }
  }, []);

  useEffect(() => {
    if (platformSub) {
      setInProgress(true)
      Object.keys(platformSub).map(key => {

        if (platformSub?.[key]?.subscription?.planName && platformSub[key].subscription.planName === EGithubPlanNames.PRO || platformSub?.[key]?.trial?.trialStatus && platformSub[key].trial.trialStatus === ETrialStatus.STARTED) {
          setCurData(prevState => {
            return {
              ...prevState,
              [key]: platformSub[key].storage
            }
          })
          setNewData(prevState => {
            return {
              ...prevState,
              [key]: platformSub[key].storage
            }
          })
        }
      })
      setInProgress(false)
      setFirstLoad(false)
    }
  }, [platformSub])

  const isEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (obj1[key].length !== obj2[key].length) {
        return false;
      }
      const sortedArray1 = obj1[key].slice().sort();
      const sortedArray2 = obj2[key].slice().sort();

      for (let i = 0; i < sortedArray1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) {
          return false;
        }
      }
    }
    return true;
  }

  const setCheck = (status, storage, platform) => {
    const newObj = {...newData}
    const arrExt = [...newObj[platform]]
    if (status && !newObj[platform]?.includes(storage)) {
      if (!newObj[platform]) {
        newObj[platform] = []
      }
      newObj[platform] = [...newObj[platform], storage]
    }
    if (!status && arrExt?.includes(storage)) {
      const indexToRemove = arrExt.indexOf(storage);
      arrExt.splice(indexToRemove, 1);
      newObj[platform] = arrExt
    }
    setNewData({...newObj})
  }

  const setStorage = async () => {
    setInProgress(true)
    try {
      await dispatch(setCloudStorage(newData))
      await dispatch(getUser()).unwrap()
      setInProgress(false)
      if (closeModal) {
        closeModal()
      }
      Swal.fire({
        title: translate('notifications.titles.success'),
        text: 'Storage successfully updated',
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError;
      setInProgress(false)

      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  const disconnectExt = async (storage: string) => {
    try {
      setInProgress(true)
      const confirmationChoice = await Swal.fire({
        title: translate('notifications.titles.warning'),
        text: `Do you really want to disconnect "${storageList.find(i => i.tag === storage)?.title}"?`,
        confirmButtonText: translate('notifications.choices.continue'),
        cancelButtonText: translate('notifications.choices.cancel'),
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: true,
        imageUrl: errorBigIcon,
      });

      if (!confirmationChoice.isConfirmed && !confirmationChoice.isDenied) {
        setInProgress(false)
        return;
      }

      await dispatch(removeCloudStorage(storage)).unwrap()
      Swal.fire({
        title: translate('notifications.titles.success'),
        text: 'Storage service successfully disconnected',
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });

      await dispatch(getUser()).unwrap()
      setInProgress(false)

    } catch (err) {
      const error = err as IError;
      setInProgress(false)

      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  return (
    <div className={closeModal ? 'storage-block storage-block--modal' : "storage-block"}>
      <div className='block-label '>
        <Typography variant="h2">External Storage</Typography>
        <div
          className="block-label-desc">{platformModal ? 'Connect your external storage for Backups.' : 'Set optional external storage locations for your backups.'}</div>
      </div>

      <div className='storage-block-container'>
        <div className='storage-block-storage-connecting'>
          {!platformModal && (
            <div className="storage-block-subtitle">
              Connect your external storage for Backups
            </div>
          )}
          <div className='storage-block-storage-connecting-list'>
            {storageList.map(item => (
              <div key={item.tag} className='storage-block-storage-connecting-item'>
                <div className='storage-item-logo'>
                  <img src={item.icon} className="platformLogo" loading="lazy"/>
                </div>
                <div className='storage-item-title'>{item.title}</div>
                {item.tag === 's3' && !connectedStorage?.includes(item.tag) && (
                  <span
                    className='aws-how-to-icon'
                    onClick={() => {
                      setIsOpenTutorial(true)
                    }}
                    title='Detailed instruction how to add users S3 bucket to BackupLABS'
                  >
                    <Info className="sliders-icon"/>
                  </span>
                )}
                <div className='storage-item-status'>
                  <Chip
                    className={connectedStorage?.includes(item.tag) ? 'success' : 'error'}
                    label={connectedStorage?.includes(item.tag) ? 'Connected' : 'Not connected'}
                  />
                </div>
                <div className='storage-item-action'>
                  <CustomTooltip
                    title={!!connectedStorage?.length && !connectedStorage?.includes(item.tag) ? 'You can only connect to one drive. Disconnect to sync to another.' : ''}>
                    <Button
                      variant={connectedStorage?.includes(item.tag) ? "outlined" : "contained"}
                      color={connectedStorage?.includes(item.tag) ? "warning" : "primary"}
                      disabled={inProgress || (!!connectedStorage?.length && !connectedStorage?.includes(item.tag)) || isUserSub}
                      onClick={() => {
                        connectedStorage?.includes(item.tag) ?
                          disconnectExt(item.tag) :
                          iniFrame() ?
                            iframeNewTabMsg() :
                            item.tag === 's3' ?
                              setIsOpenAWSConnect(true) :
                              dispatch(getCloudStorageUrl({
                                storage: item.tag,
                                platform: platformModal || ''
                              })).unwrap()
                                .then(res => {
                                  window.open(res.url, '_blank');
                                  if (closeModal) {
                                    closeModal()
                                  }
                                })
                      }}
                    >
                      {!connectedStorage?.includes(item.tag) ? 'Connect' : 'Disconnect'}
                    </Button>
                  </CustomTooltip>
                </div>
              </div>
            ))}
          </div>
        </div>
        {!platformSub && firstLoad && (
          <div className="spinner-wrapper">
            <CircularProgress/>
          </div>
        )}
        {platformSub && !firstLoad && !!connectedStorage?.length && (
          <div className='storage-block-platforms'>
            <div className="storage-block-subtitle">
              Select the apps you want to save to external storage:
            </div>
            <div className='storage-block-platforms-list'>
              {inProgress && (
                <div className="spinner-wrapper spinner-overflow">
                  <CircularProgress/>
                </div>
              )}
              <div className='storage-block-single-platform--head'>
                <div className='storage-block-single-platform-title'>App</div>
                <div className='storage-block-single-platform-status'>Status</div>
                {storageList.map(item => (
                  <div key={item.tag} className='storage-block-single-platform-storages'>
                    {item.title}
                  </div>
                ))}
              </div>
              {Object.keys(platform).map(key => {
                const isProPlanSubscription = (platformSub?.[key]?.subscription?.planName && platformSub[key].subscription.planName === EGithubPlanNames.PRO || platformSub?.[key]?.trial?.trialStatus && platformSub[key].trial.trialStatus === ETrialStatus.STARTED)
                if (key !== 'asana' && (!isUserSub || (isUserSub && roles && Object.keys(roles).includes(key)))) {
                  return (
                    <div
                      key={key}
                      className='storage-block-single-platform'
                    >
                      <div className='storage-block-single-platform-title'>
                        <img src={platform[key]?.smallLogo} className="platformLogo" loading="lazy"/>
                        {platform[key].title}
                      </div>
                      <div className='storage-block-single-platform-status'>
                        <Chip
                          className={
                            platformSub[key].isConnectionActive ?
                              !!platformSub[key].storage.length && isProPlanSubscription ?
                                'success' : 'warning'
                              : 'error'
                          }
                          label={platformSub[key].isConnectionActive ?
                            !!platformSub[key].storage.length && isProPlanSubscription ?
                              'Active' : 'Available'
                            : 'Not Connected'}
                        />
                      </div>
                      {storageList.map(item => (
                        <div key={key + item.tag} className='storage-block-single-platform-storages'>
                          <Checkbox
                            disabled={!platformSub[key].isConnectionActive || !connectedStorage?.includes(item.tag) || inProgress || (isUserSub && (roles?.[key] === 'user' || !roles?.[key]))}
                            color="primary"
                            onClick={(e) => {
                              if (!isProPlanSubscription) {
                                updateSubscriptionPlanModal({translate, platformName: key, navigate})
                              } else {
                                setCheck((e.target as HTMLInputElement).checked as boolean, item.tag, key)
                              }

                            }}
                            checked={!!newData[key]?.includes(item.tag)}
                            icon={<CheckboxInputEmpty/>}
                            checkedIcon={<CheckboxInputChecked/>}
                          />
                        </div>
                      ))}
                    </div>
                  )
                }
              })}
            </div>
          </div>
        )}
      </div>

      <div className='action-buttons'>
        {!!connectedStorage?.length && (
          <Button
            variant="contained" color="primary"
            className='set-storage-btn'
            disabled={inProgress || isEqual(curData, newData) || firstLoad}
            onClick={setStorage}
          >
            Save
          </Button>
        )}
        {platformModal && closeModal && (
          <Button
            variant="outlined" color="primary"
            className='set-storage-btn'
            disabled={inProgress}
            ml={4}
            onClick={() => {
              setNewData({...curData})
              closeModal()
            }}
          >
            Cancel
          </Button>
        )}
      </div>
      <AWSS3Tutorial isOpen={isOpenTutorial} closeNotification={() => setIsOpenTutorial(false)}/>
      <AWSS3Connection isOpen={isOpenAWSConnect} closeNotification={() => setIsOpenAWSConnect(false)}/>
    </div>
  );
};

export default ExternalStorage
