import { CircularProgress, Container, FormControl, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { emailIcon, errorBigIcon, phoneIcon, pointerIcon, iconNotifySuccess } from '../../assets/icons/icons';
import { stripesBlock1, stripesBlock2 } from '../../assets/images/contact-images';
import ValidationErrorBlock from '../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock';
import { Button, TextField } from '../../components/StyledComponents';
import { EMAIL_REGEX, MAX_INPUT_VALUE_LENGTH, MIN_INPUT_VALUE_LENGTH } from '../../models/consts';
import { IContactForm } from '../../models/inner-models';
import { IContactFormApi } from '../../models/models';
import { useAppSelector } from '../../store/hooks';
import instance from '../../store/middlewares/api';
import {getUserSelector, isHasProPlan, loadingUser} from '../../store/selectors';
import useWindowDimensions from '../../utils/windowDimensions';
import withRedirectAfterLogout from '../../utils/withRedirectAfterLogout';
import './style.scss';
import {useNavigate} from "react-router-dom";


const ContactPage: FunctionComponent = () => {
  const { isMobile, isMobileOnly } = useWindowDimensions();
  const { register, formState: { errors, isValid }, handleSubmit, setValue, reset } = useForm<IContactForm>({
    mode: 'onChange',
  });
  const { t: translate } = useTranslation();
  const userLoading = useAppSelector(loadingUser);
  const user = useAppSelector(getUserSelector);
  const isProPlan = useAppSelector(isHasProPlan);
  const navigate = useNavigate();

  useEffect(() => {
    setValue('firstName', user?.name || '');
    setValue('lastName', user?.family_name || '');
    setValue('email', user?.email || '');
  }, [user, userLoading]);

  const onSubmit: SubmitHandler<IContactForm> = ({ firstName, lastName, company, email, question }) => {
    const contactFormData: IContactFormApi = {
      first_name: firstName,
      last_name: lastName,
      company,
      email,
      question,
    };
    instance.post('contactus', contactFormData)
      .then(() => {
        Swal.fire({
          title: translate('notifications.titles.success'),
          text: 'Your request was successfully sent!',
          imageUrl: iconNotifySuccess,
          confirmButtonText: translate('notifications.choices.close'),
        })
          .then(()=> {
            reset();
            setValue('firstName', user?.name || '');
            setValue('lastName', user?.family_name || '');
            setValue('email', user?.email || '');
            navigate('/');
          });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: translate('notifications.titles.error'),
          text: 'Unfortunately, your request wasn’t sent. Please try again later',
          imageUrl: errorBigIcon,
          confirmButtonText: translate('notifications.choices.close'),
        });
      });
  };

  return (
    <div id="contact-page">
      {userLoading ?
        <div className="spinner-wrapper">
          <CircularProgress />
        </div> :
        <Container className={isMobileOnly ? 'no-padding' : ''}>
          <Typography variant="h1" gutterBottom display="inline">
            {translate('common.default_pages.contact_us')}
          </Typography>

          <div className="main-block">
            <div className="contact-info">
              <img className="hairy-div top" src={stripesBlock2} loading="lazy"/>
              <h3 className="subtitle">
                {translate('views.contact_us.love_to_hear')}
              </h3>
              <p>We would like to answer you about all questions you are interested in</p>
              <div className="contacts-block">
                <div className="feature-item">
                  <img src={pointerIcon} loading="lazy"/>
                  {translate('views.contact_us.place')}
                </div>
                <div className="feature-item">
                  <img src={phoneIcon} loading="lazy"/>
                  {translate('views.contact_us.phone')}
                </div>
                <div className="feature-item">
                  <img src={emailIcon} loading="lazy"/>
                  {translate('views.contact_us.email')}
                </div>
              </div>
              <img className="hairy-div bottom" src={stripesBlock1} loading="lazy"/>
            </div>
            <div className="form-block">
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-row ${!isMobileOnly && 'col-2'}`}>
                  <FormControl
                    fullWidth={isMobileOnly}
                    className={`${isMobileOnly && 'mb-20'} first-input`}
                  >
                    <TextField
                      label={translate('forms.common.first_name')}
                      required
                      id="first-name"
                      {...register('firstName', {
                        required: translate('forms.common.required') as string,
                        minLength: {
                          value: MIN_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.min_length'),
                        },
                        maxLength: {
                          value: MAX_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.max_length50'),
                        },
                      })}
                      error={!!errors.firstName}
                    />
                    {errors.firstName &&
                    <ValidationErrorBlock errorMessage={errors.firstName.message as string} />
                    }
                  </FormControl>
                  <FormControl fullWidth={isMobileOnly}>
                    <TextField
                      label={translate('forms.common.last_name')}
                      required
                      id="last-name"
                      {...register('lastName', {
                        required: translate('forms.common.required') as string,
                        minLength: {
                          value: MIN_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.min_length'),
                        },
                        maxLength: {
                          value: MAX_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.max_length50'),
                        },
                      })}
                      error={!!errors.lastName}
                    />
                    {errors.lastName &&
                    <ValidationErrorBlock errorMessage={errors.lastName.message as string} />
                    }
                  </FormControl>
                </div>
                <div className={`form-row ${!isMobile && 'col-2'}`}>
                  <FormControl
                    fullWidth={isMobile}
                    className={`${isMobile && 'mb-20'} first-input`}
                  >
                    <TextField
                      label={translate('forms.contact_us.fields.company')}
                      placeholder={translate('forms.contact_us.fields.company')}
                      required
                      id="company"
                      {...register('company', {
                        required: translate('forms.common.required') as string,
                        minLength: {
                          value: MIN_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.min_length'),
                        },
                        maxLength: {
                          value: MAX_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.max_length50'),
                        },
                      })}
                      error={!!errors.company}
                    />
                    {errors.company &&
                    <ValidationErrorBlock errorMessage={errors.company.message as string} />
                    }
                  </FormControl>
                  <FormControl fullWidth={isMobile}>
                    <TextField
                      required
                      label={translate('forms.common.email')}
                      id="email"
                      type="email"
                      {...register('email', {
                        required: translate('forms.common.required') as string,
                        maxLength: {
                          value: MAX_INPUT_VALUE_LENGTH,
                          message: translate('forms.common.max_length50'),
                        },
                        pattern: {
                          value: EMAIL_REGEX,
                          message: translate('forms.common.invalid_email'),
                        },
                      })}
                      error={!!errors.email}
                    />
                    {errors.email &&
                    <ValidationErrorBlock errorMessage={errors.email.message as string} />
                    }
                  </FormControl>
                </div>
                <div className="form-row">
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      label={translate('forms.contact_us.fields.your_question')}
                      required
                      id="question"
                      multiline
                      rows={4}
                      {...register('question', {
                        required: translate('forms.common.required') as string,
                        minLength: {
                          value: 2,
                          message: translate('forms.common.min_length'),
                        },
                        maxLength: {
                          value: 1000,
                          message: translate('forms.common.max_length1000')
                        }
                      })}
                      error={!!errors.question}
                    />
                    {errors.question &&
                    <ValidationErrorBlock errorMessage={errors.question.message as string} />
                    }
                  </FormControl>
                </div>
                <div className="action-row">
                  <Button
                    type="submit" variant="contained" color="primary"
                    disabled={!isValid}
                  >
                    {translate('forms.common.submit')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Container>
      }
    </div>
  );
};

export default withRedirectAfterLogout()(ContactPage);
