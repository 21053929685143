import {CircularProgress, MenuItem, OutlinedInput, Select, Typography} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import './style.scss';
import ValidationErrorBlock from "../Blocks/ValidationErrorBlock/ValidationErrorBlock";
import {Button, Divider} from '../StyledComponents';
import vatArr from './vat.json';
import {IBillingAddressPayload} from "../../models/inner-models";
import {allCountries} from 'country-region-data';

interface IBillingProps {
  onClose?: () => void;
  next?: () => void;
  upd?: (IBillingAddressPayload) => Promise<string>;
  setData: (IBillingAddressPayload) => void;
  data: IBillingAddressPayload | null | undefined;
  userPage?: boolean;
}

const BillingForm: FunctionComponent<IBillingProps> = ({
                                                         onClose,
                                                         setData,
                                                         next,
                                                         data,
                                                         userPage,
                                                         upd
                                                       }: IBillingProps) => {
  const {t: translate} = useTranslation();
  const {register, handleSubmit, setValue, clearErrors, reset, formState: {errors, isDirty}} = useForm();
  const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);
  const [country, setCountry] = useState('');
  const [countryFull, setCountryFull] = useState('');
  const [region, setRegion] = useState('');
  const [regionList, setRegionList] = useState<Array<Array<string>>>([]);
  const topCountries = ['US', 'GB', 'DE', 'FR', 'BR', 'CA']

  useEffect(() => {
    if (data && data.company_details && !isDirty) {
      const formData = {
        ...data.company_details,
        vat: data.tax_details?.value || ''
      }
      setCountry(data.company_details.country)
      setCountryFull(allCountries.find(item => item[1] === data.company_details.country)?.[0] || '')
      setRegion(data.company_details.state)
      setRegionList(allCountries.find(item => item[1] === data.company_details.country)?.[2] || [])
      reset(formData)
    }
  }, [data, isDirty])

  const clearObject = (objDirt: Record<string, unknown>) => {
    return Object.keys(objDirt)
      .filter(key => objDirt[key] !== '' && objDirt[key] !== null)
      .reduce((obj, key) => {
        obj[key] = objDirt[key];
        return obj;
      }, {})
  }
  const onSubmit = (dataForm) => {
    setIsUpdatingUser(true)

    const compDet = {
      name: dataForm.name,
      city: dataForm.city,
      country: dataForm.country,
      line1: dataForm.line1,
      line2: dataForm.line2,
      state: dataForm.state,
      postal_code: dataForm.postal_code
    }

    const billData = {
      company_details: {
        ...clearObject(compDet)
      }
    }
    if (dataForm.vat && !!dataForm.vat.length) {
      billData['tax_details'] = {
        type: vatArr.find(item => item.country === allCountries.find(item => item[1] === dataForm.country)?.[0] || '')?.enum,
        value: dataForm.vat
      }
    }
    setData({...billData})
    if (!upd) {
      setIsUpdatingUser(false)
    } else {
      upd(billData).then(() => {
        reset(dataForm)
        setIsUpdatingUser(false)
      })
    }
    if (next) {
      next()
    }
  };

  return (
    <div className="settings-block-container billing-block">
      {userPage && (
        <div className="block-label">
          <Typography variant="h2">
            User/Company Details
          </Typography>
          <div className="block-label-desc">
            Information about you or your company (usually for tax purposes).
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="name" className="input-label">
          Company legal name / Personal name *
          <OutlinedInput
            placeholder='Microsoft, LLC.'
            {...register("name", {
              required: translate('forms.common.required') as string,
            })}
            error={!!errors.name}
          />
          {errors.name && !isUpdatingUser &&
            <ValidationErrorBlock errorMessage={errors.name.message as string}/>
          }
        </label>

        <label htmlFor="country"
               className={errors.country ? "input-label width-50 select-error" : "input-label width-50"}>
          Country *
          <OutlinedInput
            readOnly
            value={country}
            sx={{display: 'none'}}
            {...register("country", {
              required: translate('forms.common.required') as string,
            })}
            error={!!errors.country}
          />
          <Select
            displayEmpty
            value={country}
            onChange={(e) => {
              if (country !== e.target.value as string) {
                setValue('state', '')
                setRegion('')
              }
              setCountry(e.target.value as string)
              setCountryFull(allCountries.find(countryItem => countryItem[1] === e.target.value as string)?.[0] || '')
              setValue('country', e.target.value)
              setRegionList(allCountries.find(countryItem => countryItem[1] === e.target.value as string)?.[2] || [])
              clearErrors('country');
            }}
            MenuProps={{id: 'restore-select'}}
            className={!!country.length ? 'billing-country' : 'billing-country select-placeholder'}
          >
            <MenuItem value='' key='dis' disabled>
              Select country...
            </MenuItem>
            {allCountries?.sort((a, b) => topCountries.indexOf(b[1]) - topCountries.indexOf(a[1])).map((item) => {
              return (
                item[1] === "AF" ?
                  <Divider/> :
                  <MenuItem value={item[1]} key={item[1]}>
                    {item[0]}
                  </MenuItem>
              )
            })}
          </Select>
          {errors.country && !isUpdatingUser &&
            <ValidationErrorBlock errorMessage={errors.country.message as string}/>
          }
        </label>

        <label htmlFor="state" className={errors.state ? "input-label width-50 select-error" : "input-label width-50"}>
          State / Region *
          <OutlinedInput
            readOnly
            value={region}
            sx={{display: 'none'}}
            {...register("state", {
              required: translate('forms.common.required') as string,
            })}
            error={!!errors.state}
          />
          <Select
            displayEmpty
            value={region}
            onChange={(e) => {
              setRegion(e.target.value as string)
              setValue('state', e.target.value)
              clearErrors('state');
            }}
            MenuProps={{id: 'restore-select'}}
            className={!!region.length ? 'billing-country' : 'billing-country select-placeholder'}
          >
            <MenuItem value='' key='dis' disabled>
              {!!country.length ? 'Select state...' : 'Select country first'}
            </MenuItem>
            {regionList?.map((item) => {
              return (
                <MenuItem value={item[1]} key={item[1]}>
                  {item[0]}
                </MenuItem>
              )
            })}
          </Select>
          {errors.state && !isUpdatingUser &&
            <ValidationErrorBlock errorMessage={errors.state.message as string}/>
          }
        </label>

        <label htmlFor="city" className="input-label ">
          City *
          <OutlinedInput
            placeholder='London'
            {...register("city", {
              required: translate('forms.common.required') as string,
            })}
            error={!!errors.city}
          />
          {errors.city && !isUpdatingUser &&
            <ValidationErrorBlock errorMessage={errors.city.message as string}/>
          }
        </label>

        <label htmlFor="line1" className="input-label width-65">
          Address *
          <OutlinedInput
            placeholder='123 Paddington Way'
            {...register("line1", {
              required: translate('forms.common.required') as string,
            })}
            error={!!errors.line1}
          />
          {errors.line1 && !isUpdatingUser &&
            <ValidationErrorBlock errorMessage={errors.line1.message as string}/>
          }
        </label>

        <label htmlFor="line2" className="input-label width-35">
          Address 2
          <OutlinedInput
            placeholder='Suite 540'
            {...register("line2", {})}
          />
        </label>

        <label htmlFor="postal_code" className="input-label">
          Zip Code / Postcode *
          <OutlinedInput
            placeholder='123456'
            {...register("postal_code", {
              required: translate('forms.common.required') as string,
            })}
            error={!!errors.postal_code}
          />
          {errors.postal_code && !isUpdatingUser &&
            <ValidationErrorBlock errorMessage={errors.postal_code.message as string}/>
          }
        </label>

        {!!countryFull.length && vatArr.find(item => item.country === countryFull) && (
          <label htmlFor="vat" className="input-label">
            VAT ID / Business Taxes ID
            <OutlinedInput
              placeholder={String(vatArr.find(item => item.country === countryFull)?.example || 'vat')}
              {...register("vat", {})}
            />
          </label>
        )}
        <div className="action-buttons">
          {onClose && (
            <Button
              variant="outlined" color="primary"
              className='newapp-back'
              onClick={onClose}
              mr={4}
            >
              Back
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isUpdatingUser}
          >
            {isUpdatingUser &&
              <div className="small-spinner-wrapper">
                <CircularProgress color="inherit" style={{width: '20px', height: '20px', marginTop: '6px'}}/>
              </div>
            }
            {userPage ? 'Update details' : 'Continue'}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default BillingForm;
