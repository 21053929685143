import {CircularProgress, Dialog, DialogContent, DialogTitle, FormControl} from '@mui/material';
import React, {FunctionComponent, useState} from 'react';
import {Button, TextField} from '../../../../../components/StyledComponents';
import {AWSCloudStoragePlatform, IError, IPoppupBase} from "../../../../../models/inner-models";
import './styyle.scss'
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {MAX_INPUT_VALUE_LENGTH, MIN_INPUT_VALUE_LENGTH} from "../../../../../models/consts";
import ValidationErrorBlock from "../../../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock";
import {useTranslation} from 'react-i18next';
import {useAppDispatch} from "../../../../../store/hooks";
import {getUser, setAWSCloudStorage} from "../../../../../store/actions";
import Swal from "sweetalert2";
import {closeModalIcon, errorBigIcon, toastSuccess} from "../../../../../assets/icons/icons";
import {decoratePopUpMessage} from "../../../../../utils/popUpTextDecorator";
import Autocomplete from "@mui/material/Autocomplete";

const awsRegion = ['us-east-2', 'us-east-1', 'us-west-1', 'us-west-2', 'af-south-1', 'ap-east-1', 'ap-south-2', 'ap-southeast-3', 'ap-southeast-5', 'ap-southeast-4', 'ap-south-1', 'ap-northeast-3', 'ap-northeast-2', 'ap-southeast-1', 'ap-southeast-2', 'ap-southeast-7', 'ap-northeast-1', 'ca-central-1', 'ca-west-1', 'eu-central-1', 'eu-west-1', 'eu-west-2', 'eu-south-1', 'eu-west-3', 'eu-south-2', 'eu-north-1', 'eu-central-2', 'il-central-1', 'mx-central-1', 'me-south-1', 'me-central-1', 'sa-east-1', 'us-gov-east-1', 'us-gov-west-1']

const AWSS3Connection: FunctionComponent<IPoppupBase> = ({isOpen, closeNotification}: IPoppupBase) => {
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const { control, register, formState: { errors }, handleSubmit, reset } = useForm<AWSCloudStoragePlatform>({
    mode: 'onChange',
  });
  const [inProgress, setInProgress] = useState<boolean>(false)

  const handleClose = () => {
    if (inProgress) return
    reset()
    if (closeNotification) {
      closeNotification();
    }
  }

  const onSubmit: SubmitHandler<AWSCloudStoragePlatform> = async (data)  => {
    try {
      setInProgress(true)
      await dispatch(setAWSCloudStorage(data)).unwrap()
      await dispatch(getUser()).unwrap()
      handleClose()
      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: 'AWS S3 bucket successfully connected',
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (e) {
      const error = e as IError;

      await Swal.fire({
        imageUrl: errorBigIcon,
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        confirmButtonText: translate('notifications.choices.close'),
      });
    } finally {
      setInProgress(false)
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="aws-modal-title"
      aria-describedby="aws-modal-info"
      classes={{
        paper: 'notification-modal aws-modal aws-connection-modal',
      }}
    >
      <div className="close-btn" onClick={handleClose}>
        <img src={closeModalIcon} loading="lazy"/>
      </div>
      <DialogTitle id="aws-modal-title">
        Add S3 bucket to BackupLABS
      </DialogTitle>
      <DialogContent>
        <div id="aws-modal-connect">
          {inProgress && (
            <div className="spinner-wrapper">
              <CircularProgress/>
            </div>
          )}
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <label>Access key ID</label>
            <FormControl fullWidth>
              <TextField
                placeholder={'accessKeyID'}
                required
                id="accessKeyID"
                {...register('accessKeyID', {
                  required: translate('forms.common.required') as string,
                  minLength: {
                    value: MIN_INPUT_VALUE_LENGTH,
                    message: translate('forms.common.min_length'),
                  },
                  maxLength: {
                    value: MAX_INPUT_VALUE_LENGTH,
                    message: translate('forms.common.max_length50'),
                  },
                })}
                disabled={inProgress}
                error={!!errors.accessKeyID}
              />
              {errors.accessKeyID &&
                <ValidationErrorBlock errorMessage={errors.accessKeyID.message as string}/>
              }
            </FormControl>
            <FormControl fullWidth>
              <label>Secret access key</label>
              <TextField
                placeholder={'secretAccessKey'}
                required
                id="secretAccessKey"
                {...register('secretAccessKey', {
                  required: translate('forms.common.required') as string,
                  minLength: {
                    value: MIN_INPUT_VALUE_LENGTH,
                    message: translate('forms.common.min_length'),
                  },
                  maxLength: {
                    value: MAX_INPUT_VALUE_LENGTH,
                    message: translate('forms.common.max_length50'),
                  },
                })}
                disabled={inProgress}
                error={!!errors.secretAccessKey}
              />
              {errors.secretAccessKey &&
                <ValidationErrorBlock errorMessage={errors.secretAccessKey.message as string}/>
              }
            </FormControl>
            <FormControl fullWidth>
              <label>Region</label>
              <Controller
                name='region'
                control={control}
                {...register('region', {
                  required: translate('forms.common.required') as string
                })}
                render={({field: {onChange, value}}) => (
                  <Autocomplete
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => {
                      return option?.label
                    }}
                    onChange={(event, item) => {
                      onChange(item.id);
                    }}
                    disableClearable={true}
                    value={value}
                    options={
                      [...awsRegion.map((item) => {
                        return (
                          {id: item, label: item}
                        )
                      })]
                    }
                    renderInput={(params) => <TextField {...params} placeholder='Region' error={!!errors.region}/>}
                  />
                )}
              />
              {errors.region &&
                <ValidationErrorBlock errorMessage={errors.region.message as string}/>
              }
            </FormControl>
            <FormControl fullWidth>
              <label>Bucket name</label>
              <TextField
                placeholder={'bucketName'}
                required
                id="bucketName"
                {...register('bucketName', {
                  required: translate('forms.common.required') as string,
                  minLength: {
                    value: MIN_INPUT_VALUE_LENGTH,
                    message: translate('forms.common.min_length'),
                  },
                  maxLength: {
                    value: MAX_INPUT_VALUE_LENGTH,
                    message: translate('forms.common.max_length50'),
                  },
                })}
                disabled={inProgress}
                error={!!errors.bucketName}
              />
              {errors.bucketName &&
                <ValidationErrorBlock errorMessage={errors.bucketName.message as string}/>
              }
            </FormControl>
            <div className="action-buttons">
              <Button
                type="submit"
                variant="contained" color="primary"
                disabled={inProgress}
                // onClick={() => onSubmit()}
              >
                Connect
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                }}
                disabled={inProgress}
                variant="outlined" color="primary"
              >
                Close
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>


    </Dialog>
  );
};

export default AWSS3Connection;
