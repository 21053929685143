import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Theme
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Button, Typography} from '../../components/StyledComponents';
import styled, {ThemeProps, withTheme} from 'styled-components';
import {useTranslation} from "react-i18next";
import {Chart} from "react-chartjs-2";
import {ArcElement, Chart as ChartJS, ChartData, Legend, Tooltip, DoughnutController} from "chart.js";
import {orange} from "@mui/material/colors";
import {AlertTriangle} from "react-feather";
import {useNavigate} from "react-router-dom";
import {EPlatformsNames} from "../../models/consts";
import {useAppSelector} from "../../store/hooks";
import {isSubUser} from "../../store/selectors";

ChartJS.register(ArcElement, Tooltip, Legend, DoughnutController);

const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  height: 392px;
  color: #344054;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding: 24px;
    box-sizing: border-box;
  }
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
  margin-bottom: 32px;

  canvas {
    z-index: 1;
    position: absolute;
  }
`;
const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 0;
`;
const TableRow = styled(MuiTableRow)`
  height: 42px;

  div {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 5px;
    float: left;
    margin-top: 8px;
  }

  &:first-child {
    div {
      background: #0283FA;
    }
  }

  &:last-child {
    div {
      background: #EAECF0;
    }

    th, td {
      border: 0;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  padding: 0 4px;
  font-weight: 400;
  font-size: 14px;
  color: #6B7280;
`;

const GreyText = styled.span`
  color: #6B7280;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize
`;

type StatsProps = {
  title: string;
  platform: string;
  plan: string;
  itemsAmount: Record<EPlatformsNames, {
    main: { total: number; backup: number };
    sub?: { total: number; backup: number };
  }>;
  isTrialEnd: boolean;
  isConnect: boolean;
};

const Stats: React.FC<StatsProps> = ({
                                       title,
                                       platform,
                                       itemsAmount,
                                       theme,
                                       isTrialEnd,
                                       plan,
                                       isConnect
                                     }: ThemeProps<Theme>) => {
  const {t: translate} = useTranslation();
  const [pie, setPie] = useState<ChartData<"doughnut">>();
  const navigate = useNavigate();
  const isUserSub = useAppSelector(isSubUser);
  const [total, setTotal] = useState<number>(0);
  const [backup, setBackup] = useState<number>(0);

  useEffect(() => {
    const labels: string[] = ['Protected', 'Unprotected']

    let allItems = itemsAmount.main.total
    let backupItems = itemsAmount.main.backup
    if (itemsAmount.sub) {
      allItems += itemsAmount.sub.total
      backupItems += itemsAmount.sub.backup
    }
    setTotal(allItems)
    setBackup(backupItems)
    const size: number[] = [backupItems, allItems - backupItems]

    if (size[1] < 0) size[1] = 0
    setPie({
      labels: labels,
      datasets: [
        {
          data: size,
          backgroundColor: [
            '#0283FA',
            '#EAECF0',
            orange[500],
            theme.palette.grey[200],
          ],
          borderRadius: 50,
          borderColor: theme.palette.background.paper,
          borderWidth: 0,
        },
      ],
    })
  }, [itemsAmount])

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: ()  => {
            return '';
          },
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const index = tooltipItem.dataIndex;
            const label = tooltipItem.label;
            const value = dataset.data[index];

            return ` ${label}: ${value}`;
          }
        },
      }
    },
    radiusBackground: {
      color: '#6BF1FB'
    },
    cutout: '80%',
  };

  const backgroundColorPlugin = {
    id: "doughnutBackground",
    beforeDraw (chart) {
      const { ctx, chartArea } = chart;
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
      const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;

      ctx.save();
      ctx.beginPath();
      ctx.arc(centerX, centerY, outerRadius, 0, 2 * Math.PI); // Outer circle
      ctx.arc(centerX, centerY, innerRadius, 0, 2 * Math.PI, true); // Inner cutout (hole)
      ctx.fillStyle = "#EAECF0"; // ✅ Background color under the arcs
      ctx.fill();
      ctx.restore();
    }
  };
  return (
    <Card>
      <CardContent>
        <Typography variant="h2" mb='24px'>
          {title} Protection
        </Typography>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="h1">
              {backup > 0 ? Math.round((backup) / total * 100) : 0}%
            </Typography>
          </DoughnutInner>
          {pie && (
            <Chart type="doughnut" data={pie} options={options}  plugins={[backgroundColorPlugin]}/>
          )}
        </ChartWrapper>
        <Table style={{marginTop: (isTrialEnd || !isConnect) ? '-8px' : '0'}}>
          {isConnect && !isTrialEnd ? (
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <div/>
                    Protected
                  </TableCell>
                  <TableCell align="right">
                    <GreyText>
                      {`${translate(`views.bulk_restore.itemName.${platform}.many`)}: ${itemsAmount.main.total}`}
                      {itemsAmount.sub ?
                        ` / ${translate(`views.bulk_restore.itemName.${platform}_sub.many`)}: ${itemsAmount.sub.total}` : ''
                      }
                    </GreyText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <div/>
                    Unprotected
                  </TableCell>
                  <TableCell align="right">
                    <GreyText>
                      {`${translate(`views.bulk_restore.itemName.${platform}.many`)}: ${itemsAmount.main.backup}`}
                      {itemsAmount.sub ?
                        ` / ${translate(`views.bulk_restore.itemName.${platform}_sub.many`)}: ${itemsAmount.sub.backup}` : ''
                      }
                    </GreyText>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) :
            <TableBody className='stat-badges'>
              <TableRow>
                <TableCell align="left" className='badge-left'>
                  <div className='badge-left-ico'>
                    <AlertTriangle className="feather-icon-in-button"/>
                  </div>
                  {isConnect ? `Your ${plan ? 'free trial' : 'subscription'} has expired.` : 'Account is not connected'}
                </TableCell>
              </TableRow>
              <TableRow>
                {!isUserSub && (
                  <TableCell align="center" className='badge-right'>
                    <Button variant="contained" color="primary" fullWidth
                            onClick={() => {
                              if (isConnect) {
                                localStorage.setItem('checkout-platform', platform)
                                navigate('/checkout');
                              } else {
                                navigate('app-connector')
                              }
                            }}
                            style={{minWidth: '120px'}}
                    >
                      {!isConnect ? translate('subscriptions.connect_now') : translate('notifications.choices.subscribe')}
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          }
        </Table>
      </CardContent>
    </Card>
  );
};

export default withTheme(Stats);
