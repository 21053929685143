import {CardContent, CircularProgress} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2';
import {errorBigIcon, iconNotifyWarningInfo} from '../../../../assets/icons/icons';
import NoSubscriptionBlock from '../../../../components/Blocks/NoSubscriptionBlock/NoSubscriptionBlock';
import {Button, Card, FormControl, TextField} from '../../../../components/StyledComponents';
import {EPlatformsNames, ESubscriptionPlans, ETrialStatus} from '../../../../models/consts';
import {IError, IGitlabUserAddEditProps} from '../../../../models/inner-models';
import {addGitlabUser, editGitlabUser} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {trelloTrialStatus} from '../../../../store/selectors';
import {decoratePopUpMessage} from '../../../../utils/popUpTextDecorator';
import './style.scss';
import {ExternalLink} from "react-feather";
import Platform from "../../../../models/platforms-data";

const GitlabUserAddEdit: FunctionComponent<IGitlabUserAddEditProps> = ({
                                                                         user,
                                                                         subPlan,
                                                                         onClose
                                                                       }: IGitlabUserAddEditProps) => {
    const dispatch = useAppDispatch();
    const {t: translate} = useTranslation();
    const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);
    const trialState = useAppSelector(trelloTrialStatus);

    const {register, setValue, trigger, reset} = useForm({
      mode: 'onChange',
    });

    useEffect(() => {
      reset();
      register('username', {
        required: translate('forms.common.required') as string,
        minLength: {
          value: 2,
          message: translate('forms.common.min_length'),
        },
      });
    }, [register]);

    useEffect(() => {
      if (user) {
        setValue('username', user.username);
        trigger(['username']);
      }
    }, [user]);

    const connectUser = async () => {
      try {
        setIsUpdatingUser(true);
        if (user) {
          const confirmationChoice = await Swal.fire({
            title: translate('notifications.titles.warning'),
            text: translate('notifications.gitlab_user.change'),
            confirmButtonText: translate('notifications.choices.continue'),
            showConfirmButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            cancelButtonText: translate('notifications.choices.cancel'),
            showCancelButton: true,
            imageUrl: iconNotifyWarningInfo,
          });

          if (!confirmationChoice.isConfirmed) {
            return;
          }
        }

        const response = await dispatch(user ? editGitlabUser() : addGitlabUser()).unwrap();

        window.open(response.url, '_self');
      } catch (err) {
        const error = err as IError;
        console.log(err);

        Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
        });
      } finally {
        setIsUpdatingUser(false);
      }
    };

    return (
      <>
        {subPlan?.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED ?
          <NoSubscriptionBlock platformName={EPlatformsNames.GITLAB}/>
          :
          <Card mb={6}>
            <CardContent>
              <div className='newapp-step-two-log'>
                <p className='newapp-step-two-title'>
                  Connecting to {Platform.gitlab.title}
                </p>
                <div className='newapp-step-two-app'>
                  <div className='platformLogoContainer'>
                    <img className="platformLogo" src={Platform.gitlab.smallLogo} loading="lazy"/>
                  </div>
                  <p className='newapp-step-two-app-title'>
                    {Platform.gitlab.title}
                  </p>
                </div>
              </div>
              <p className='newapp-step-two-desc'>
                Please authorize BackupLABS to access your {Platform.gitlab.title} cloud data:
              </p>
              <div id="gitlab-add-user-description">
                {user && user?.username && <div className="form-row">
                  <FormControl className="input-name">
                    <TextField
                      required
                      id="user-name"
                      name="username"
                      placeholder={translate('forms.gitlab_add_edit_user.account_name')}
                      defaultValue={user?.username || ''}
                      disabled={true}
                    />
                  </FormControl>
                </div>
                }
                <div className="add-change-user">
                  {onClose && (
                    <Button
                      variant="outlined" color="primary"
                      className='newapp-back'
                      onClick={onClose}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    onClick={connectUser}
                    variant="contained"
                    color="primary"
                    disabled={isUpdatingUser}
                  >
                    {isUpdatingUser &&
                      <div className="small-spinner-wrapper">
                        <CircularProgress color="inherit" style={{width: '20px', height: '20px', marginTop: '6px'}}/>
                      </div>
                    }
                    {isUpdatingUser
                      ? translate('notifications.choices.processing')
                      : !user ? translate('forms.gitlab_add_edit_user.add_new_account')
                        : translate('forms.gitlab_add_edit_user.change_account')
                    }
                    <ExternalLink className="feather-icon-in-button icon-ml"/>
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        }
      </>
    );
  }
;

export default GitlabUserAddEdit;
