import {CircularProgress, Dialog, DialogContent, DialogTitle, InputAdornment} from '@mui/material';
import React, {FunctionComponent, memo, useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  closeModalIcon, searchIcon
} from '../../../../../assets/icons/icons';
import {INotionScope} from '../../../../../models/inner-models';
import {
  fetchSubItemsForNotionUser
} from '../../../../../store/actions';
import {useAppDispatch} from '../../../../../store/hooks';
import './style.scss';
import {Button} from "../../../../../components/StyledComponents";
import Collapse from '@mui/material/Collapse';
import {ChevronDown, ChevronUp} from "react-feather";
import {SearchInput} from "../../../../../styles/components/MainInputElements";

type ScopeDataType = {
  id: string,
  title: string,
  object: string,
  children?: {
    id: string,
    title: string,
    object: string,
  }[]
}
const NotionScope: FunctionComponent<INotionScope> = ({
                                                        isOpen,
                                                        closeNotification,
                                                        workspaceScopeId,
                                                        modalScopeData,
                                                        workspaceId
                                                      }: INotionScope) => {
  const dispatch = useAppDispatch();
  const {t: translate} = useTranslation();
  const [workspaceScopeData, setWorkspaceScopeData] = useState<Array<ScopeDataType>>([]);
  const [workspaceScopeLoading, setWorkspaceScopeLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<string>('');
  const [searchTerm, setSearch] = useState<string>('');

  useEffect(() => {
    setWorkspaceScopeLoading(true);
    setSearch('')
  }, [isOpen]);

  useEffect(() => {
    async function getAvailableBoards() {
      try {
        const scope = await dispatch(fetchSubItemsForNotionUser({workspaceScopeId, workspaceId})).unwrap();
        if (!scope || !scope.length) {
          setWorkspaceScopeData([]);
          setWorkspaceScopeLoading(false);
          return;
        }

        setWorkspaceScopeData(scope);

        setWorkspaceScopeLoading(false);
      } catch (err) {
        console.log(err);
        setWorkspaceScopeLoading(false);
      }
    }

    if (isOpen) {
      getAvailableBoards();
    }
  }, [isOpen]);


  const handleClose = useCallback(() => {
    closeNotification && closeNotification();
  }, []);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim());
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="notion-repo-edit-title"
        aria-describedby="notion-repo-edit-description"
        classes={{
          paper: 'add-edit-notion-modal',
        }}
      >
        <DialogTitle id="notion-repo-edit-title">
          {translate('forms.notion_workspace_scope.title')}
        </DialogTitle>
        <div className="close-btn" onClick={handleClose}>
          <img src={closeModalIcon} loading="lazy"/>
        </div>

        <DialogContent>
          <div id="notion-repo-edit-description">
              <div className="username-block">
                <div className='notion-add-select-all'>
                  <div className='title-line'>
                    {translate('forms.notion_workspace_scope.sub_data')}
                  </div>
                  <div className='desc-line'>
                    {modalScopeData.replaceAll('&#x2F;', '/')}
                  </div>
                </div>
                <div className="search-block">
                  <SearchInput
                    className="search-field"
                    type="text"
                    placeholder="Search item..."
                    value={searchTerm}
                    onChange={onSearch}
                    endAdornment={
                      <InputAdornment position="end" className="search-icon">
                        <img src={searchIcon} loading="lazy"/>
                      </InputAdornment>
                    }
                  />
                </div>
              </div>

              {workspaceScopeLoading &&
                <div className="spinner-wrapper">
                  <CircularProgress/>
                </div>
              }
              {!workspaceScopeLoading &&
                <div className="scrollable-div">
                  {!!workspaceScopeData.length && (
                    <div className="form-row row-head" key='head-0'>
                      <div className="scope-item-name head">Item title</div>
                      <div className='scope-item-type head'>Item type</div>
                      <div className='scope-item-sub head'></div>
                    </div>
                  )}
                  {!!workspaceScopeData.length ?
                    workspaceScopeData.filter((i) => (i?.title.toLowerCase().includes(searchTerm.toLowerCase()) || !!i?.children?.filter(j => j?.title.toLowerCase().includes(searchTerm.toLowerCase())).length))?.length ?
                    workspaceScopeData.filter((i) => (i?.title.toLowerCase().includes(searchTerm.toLowerCase()) || !!i?.children?.filter(j => j?.title.toLowerCase().includes(searchTerm.toLowerCase())).length)).map((item, index) => {
                      return (
                        <>
                          <div
                            key={`${item.object}-${index}`}
                            className="form-row"
                            style={{
                              background: searchTerm && !!item?.children?.filter(j => j?.title.toLowerCase().includes(searchTerm.toLowerCase())).length ? '#DCF0FF' : '#fff',
                              cursor: item.children ? 'pointer' : 'default'
                            }}
                            onClick={() => {
                              if (open === `${item.object}-${index}`) {
                                setOpen('')
                              } else {
                                setOpen(`${item.object}-${index}`)
                              }
                            }}
                          >
                            <div className="scope-item-name">
                              {item.title}
                            </div>
                            <div className='scope-item-type'>{item.object}</div>
                            <div className='scope-item-sub'>
                              {!!item.children?.length ?
                                open === `${item.object}-${index}` ?
                                  <ChevronUp/> :
                                  <ChevronDown/> :
                                ''
                              }
                            </div>
                          </div>
                          {!!item.children?.length && (
                            <Collapse in={open === `${item.object}-${index}`} timeout="auto" unmountOnExit>
                              {item.children?.filter(j => j?.title.toLowerCase().includes(searchTerm.toLowerCase())).map((subItem, indexSub) => {
                                return (
                                  <div
                                    key={`${subItem.object}-${indexSub}`}
                                    className="form-row"
                                    style={{
                                      background: '#F7F9FC'
                                    }}
                                  >
                                    <div className='scope-item-sub'>

                                    </div>
                                    <div className="scope-item-name">
                                      {subItem.title}
                                    </div>
                                    <div className='scope-item-type'>{subItem.object}</div>
                                  </div>
                                )
                              })}
                            </Collapse>
                          )}
                        </>
                      )
                    }):
                      <div className="form-row with-overflow empty-notion-search">
                        No Search Results
                      </div> :
                    <div className="form-row with-overflow">
                      The list of backup scope not available.
                    </div>
                  }
                </div>}
          </div>
          <div className="action-buttons">
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              {translate('notifications.choices.close')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(NotionScope);
