import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import { errorBigIcon } from '../../../assets/icons/icons';
import { AuthWrapper as Wrapper } from '../../../components/StyledComponents';
import { IError } from '../../../models/inner-models';
import { ISignupConfirm } from '../../../models/models';
import { confirmUserRegistration } from '../../../store/actions';
import { useAppDispatch } from '../../../store/hooks';
import { decoratePopUpMessage } from '../../../utils/popUpTextDecorator';
import '../style.scss';

const useStyles = makeStyles({
  spinner: {
    color: '#319CFF',
  },
  center: {
    textAlign: 'center'
  },
  title: {
    // fontWeight: 600,
    marginBottom: '16px'
  }
});

const ConfirmedSignup: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(useLocation().search);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmFlag] = useState(false);

  useEffect(() => {
    const content = params.get('content');
    const iv = params.get('iv');
    if (content && iv) {
      const confirmationInfo: ISignupConfirm = {
        iv: iv,
        content: content,
      };

      dispatch(confirmUserRegistration(confirmationInfo))
        .unwrap()
        .then(() => {
          setConfirmFlag(true);
          navigate('/auth/login?isConfirmed=true' )
            // window.location.pathname = '/auth/login'
        })
        .catch((err) => {
          const error = err as IError

          console.log(error);
          setConfirmFlag(false);
          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(err.error as string),
            imageUrl: errorBigIcon,
            confirmButtonText: translate('notifications.choices.close'),
          }).then(()=>navigate('/auth/login' ));
        })
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <div id="confirmed-signup-page">
      <Wrapper>
        {loading ?
          <div className="spinner-wrapper">
            <CircularProgress className={classes.spinner} />
          </div>
          : confirmed ?
            <div className={classes.center}>
              <div className={classes.title}>
                {translate('forms.sign_up.verification.successfully_register_1')}
              </div>
              <Link to="/auth/login" className="link">
                {translate('forms.sign_up.verification.login_page')}
              </Link>
            </div> :
            <div className="something-went-wrong">
              {translate('forms.sign_up.verification.something_went_wrong')}
            </div>
        }
      </Wrapper>
    </div>
  );
};

export default ConfirmedSignup;
