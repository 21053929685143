import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { Button } from '../../../../../components/StyledComponents';
import {IPoppupBase} from "../../../../../models/inner-models";
import './styyle.scss'
import {closeModalIcon} from "../../../../../assets/icons/icons";

const AWSS3Tutorial: FunctionComponent<IPoppupBase> = ({isOpen, closeNotification}: IPoppupBase) => {

  const handleClose = () => {
    if (closeNotification) {
      closeNotification();
    }
  }

  const jsonCode = `{
    "Version": "2012-10-17",
    "Statement": [
      {
        "Effect": "Allow",
          "Action": [
            "s3:PutObject",
            "s3:PutObjectAcl"
          ],
          "Resource": "arn:aws:s3:::backuplabs/*"
      }
    ]
  }`;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="aws-modal-title"
      aria-describedby="aws-modal-info"
      classes={{
        paper: 'notification-modal aws-modal',
      }}
    >
      <div className="close-btn" onClick={handleClose}>
        <img src={closeModalIcon} loading="lazy"/>
      </div>
      <DialogTitle id="aws-modal-how-title">
        Detailed instruction how to add users S3 bucket to BackupLABS
      </DialogTitle>
      <DialogContent>
        <div id="aws-modal-info">
          <h2>Step 1: Create an S3 Bucket</h2>
          <ol>
            <li><b>Log in to the AWS account</b> where the bucket will be created.</li>
            <li>Go to <b>AWS Management Console → S3</b> → Click <b>&quot;Create bucket&quot;</b>.</li>
            <li><b>Bucket name</b>: Choose a unique name (e.g., <code>backuplabs</code>).</li>
            <li><b>Block Public Access</b>: Keep <b>all options checked</b> (recommended for security).</li>
            <li><b>Bucket versioning</b> (optional): Enable if you need version control for backups.</li>
            <li><b>Click &quot;Create bucket&quot;</b>.</li>
          </ol>

          <h2>Step 2: Create an IAM User</h2>
          <ol>
            <li>Go to <b>AWS Management Console → IAM → Users</b>.</li>
            <li>Click <b>&quot;Create user&quot;</b>.</li>
            <li><b>User name:</b> Choose a meaningful name (e.g., <code>s3-backuplabs-user</code>).</li>
            <li>Select <b>&quot;Provide user access to the AWS Management Console&quot; → Uncheck this option</b> (you
              only need programmatic access).
            </li>
            <li>Click <b>&quot;Next&quot;</b>.</li>
          </ol>
          <h2>Step 3: Create an IAM Policy for S3 Access</h2>
          <ol>
            <li>In the AWS Console, go to <b>IAM → Policies</b> → Click <b>&quot;Create policy&quot;</b>.</li>
            Click the <b>JSON</b> tab and paste the following policy:
            <pre>
              {jsonCode}
            </pre>
            <li>
              <ul>
                <li>Replace <code>&quot;backuplabst&quot;</code> with the <b>actual bucket name</b>.</li>
                <li>This policy allows the user to <b>upload files</b> but not delete or list them.</li>
              </ul>
            </li>
            <li>Click <b>Next</b>, give the policy a <b>name</b> (e.g., <code>S3UploadPolicy</code>), and <b>Create
              Policy</b>.
            </li>
          </ol>

          <h2>Step 4: Attach the Policy to the IAM User</h2>
          <ol>
            <li>Go to <b>IAM → Users →</b> → Select the newly created user (<code>s3-backuplabs-user</code>).</li>
            <li>Click <b>&quot;Permissions&quot; → &quot;Add permissions&quot; → &quot;Attach policies
              directly&quot;</b>.
            </li>
            <li>Search for the policy <b>S3UploadPolicy</b> and select it.</li>
            <li>Click <b>Next → Review → Add permissions</b>.</li>
          </ol>

          <h2>Step 5: Generate Access Keys for the IAM User</h2>
          <ol>
            <li>Go to <b>IAM → Users →</b> Click on the <b>user</b> (<code>s3-backuplabs-user</code>).</li>
            <li>Click <b>&quot;Security credentials&quot;</b> → Scroll to <b>&quot;Access keys&quot;</b>.</li>
            <li>Click <b>&quot;Create access key&quot;</b>.</li>
            <li><b>Select use case</b>: Choose <b>&quot;Application running outside AWS&quot;</b>.</li>
            <li>Click <b>Next</b> and then <b>Create access key</b>.</li>
            <li><b>Copy the Access Key ID and Secret Access Key</b> → Store them in a secure place (AWS will not show
              them again).
            </li>
          </ol>
        </div>
      </DialogContent>

      <div className="action-buttons">
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="contained" color="primary"
        >
          I understand
        </Button>
        <Button
          onClick={() => {
            window.open(`https://bl-public-media.s3.amazonaws.com/Detailed+instruction+how+to+add+users+S3+bucket+to+BackupLABS.pdf`, '_blank');
          }}
          variant="contained" color="secondary"
        >
          Download PDF
        </Button>
      </div>
    </Dialog>
  );
};

export default AWSS3Tutorial;
