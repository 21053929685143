import './style.scss';
import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../../components/StyledComponents";
import {ArrowRight, RefreshCcw} from "react-feather";
import {Checkbox, CircularProgress, MenuItem, Select} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {CheckboxInputChecked, CheckboxInputEmpty} from "../../../../../assets/icons/icons";

const useStyles = makeStyles({
    spinner: {
      color: '#319CFF',
    },
  },
);

interface IMatchData {
  restoredProjectColumn: {
    name: string;
    statusesIds?: string[]
  };
  backedUpProjectColumn: {
    transitionId: string;
    name: string;
  }
}

interface IRestoreModalProps {
  platformName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  projName: Record<string, any>;
  close: () => void;
  restore: () => void;
  fillData: (obj: IMatchData) => void;
  isBulk?: boolean
  matchData?: Array<IMatchData>
}

const JiraColumnMatch: FunctionComponent<IRestoreModalProps> = ({
                                                                  close,
                                                                  restore,
                                                                  projName,
                                                                  // platformName,
                                                                  date,
                                                                  fillData,
                                                                  isBulk,
                                                                  matchData
                                                                }) => {
  const {t: translate} = useTranslation();
  const classes = useStyles();
  const [check, setCheck] = useState<boolean>(false);

  const onSubmit = () => {
    // if (!matchData?.length) {
    //
    // }
    restore()
    close()
  }

  return (
    <div className='jira-column-modal'>
      {!date?.backedUpProjectColumns ?
        <div className="spinner-wrapper">
          <CircularProgress className={classes.spinner}/>
        </div> :
        <div className='restore-content'>
          <p className='restore-content-line-top'>
            You can optionally select the columns of the backed up project to be restored in the columns of the selected
            project.
          </p>
          <div className='jira-match-row title-line'>
            <div className='jira-row-title'>From: <b>{projName.old}</b></div>
            <div className='jira-row-title'>To: <b>{projName.new}</b></div>
          </div>

          {date?.backedUpProjectColumns?.map(item => {
            let indexToUpd = -1
            if (matchData) {
              indexToUpd =  matchData?.findIndex(elem => elem.backedUpProjectColumn.transitionId === item.transitionId);
            }
            return (
              <div key={item.transitionId} className='jira-match-row'>
                <div className='jira-row-name'>{item.name}</div>
                <ArrowRight className='feather-icon-in-button'/>
                <Select
                  defaultValue={indexToUpd !== -1 ? matchData?.[indexToUpd]?.restoredProjectColumn?.name : ''}
                  MenuProps={{id: 'restore-select'}}
                  className='restore-item-select-ver jira-select'
                  onChange={(e) => {
                    fillData({
                      restoredProjectColumn: {
                        name: e.target.value as string
                      },
                      backedUpProjectColumn: {
                        transitionId: item.transitionId,
                        name: item.name
                      }
                    })
                  }}
                >
                  {date?.restoredProjectColumns?.map((elem, index) => (
                    <MenuItem value={elem.name} key={index}>
                      {elem.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )
          })}

          <div>
            <Checkbox
              color="primary"
              onChange={(e) => {
                setCheck(e.target.checked as boolean)
              }}
              icon={<CheckboxInputEmpty/>}
              checkedIcon={<CheckboxInputChecked />}
            />
            I don&apos;t want to match all or some of columns
          </div>
          <div className="action-buttons">
            {!matchData?.length && (
              <Button variant="outlined" color="primary" onClick={close}>
                {translate('common.buttons.cancel')}
              </Button>
            )}
            <Button
              className="restore-btn" variant="contained"
              color="primary" onClick={onSubmit}
              disabled={date?.backedUpProjectColumns.length > (matchData?.length || 0) && !check }
            >
              {!isBulk && (
                <RefreshCcw className='feather-icon-in-button'/>
              )}
              {isBulk ? 'Confirm' : translate('common.buttons.restore')}
            </Button>
          </div>
        </div>
      }
    </div>
  )
}

export default (JiraColumnMatch)
