import {InputAdornment, Typography} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {SubmitHandler, useForm, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert2';
import {errorBigIcon, toastSuccess} from '../../../../assets/icons/icons';
import ValidationErrorBlock from '../../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock';
import IsVisibleButton from '../../../../components/Buttons/IsVisibleButton/IsVisibleButton';
import {Button, FormControl, TextField} from '../../../../components/StyledComponents';
import {MAX_INPUT_VALUE_LENGTH, OLD_PASSWORD_REGEX, PASSWORD_REGEX} from '../../../../models/consts';
import {IError, IPasswordsVisiblity} from '../../../../models/inner-models';
import {IChangePassword} from '../../../../models/models';
import {changePassword} from '../../../../store/actions';
import {useAppDispatch} from '../../../../store/hooks';
import {decoratePopUpMessage} from '../../../../utils/popUpTextDecorator';

import '../style.scss';
import {zxcvbn, zxcvbnOptions} from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';

const ChangePasswordForm: FunctionComponent = () => {
  const {register, reset, formState: {errors, isValid}, handleSubmit, control} = useForm<IChangePassword>({
    mode: 'onChange',
  });
  const dispatch = useAppDispatch();
  const {t: translate} = useTranslation();
  const [visible, setPasswordVisiblity] = useState<IPasswordsVisiblity>({
    password: false,
    confirmPassword: false,
    oldPassword: false,
  });
  const [notMatchError, setNotMatchError] = useState<boolean>(false);
  const [formKey, setFormKey] = useState<number>(0);
  const mainPass = useWatch({control, name: 'password'});
  const confirmPass = useWatch({control, name: 'confirmPassword'});

  const handleClickShowPassword = (field: keyof IPasswordsVisiblity) => () => {
    setPasswordVisiblity({...visible, [field]: !visible[field]});
  };

  const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
  }
  zxcvbnOptions.setOptions(options)

  useEffect(() => {
    if (mainPass && confirmPass && mainPass !== confirmPass) {
      setNotMatchError(true);
    } else {
      setNotMatchError(false);
    }
  }, [mainPass, confirmPass]);

  const onSubmit: SubmitHandler<IChangePassword> = (data) => {
    if (!notMatchError) {
      dispatch(changePassword(data))
        .unwrap()
        .then(() => {
          Swal.fire({
            title: translate('notifications.titles.success'),
            text: 'Your password was changed',
            toast: true,
            position: 'top-end',
            timerProgressBar: true,
            showConfirmButton: false,
            showCloseButton: true,
            imageUrl: toastSuccess,
            timer: 3000,
          });
        })
        .catch((err) => {
          const error = err as IError;

          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.error as string),
            imageUrl: errorBigIcon,
            confirmButtonText: translate('notifications.choices.close'),
          });
        })
        .finally(() => {
          reset()
          setFormKey(prevState => prevState + 1)
        });
    }
  };

  return (
    <div className="settings-block-container password-block">
      <div className="block-label">
        <Typography variant="h2">Account Security</Typography>
        <div className="block-label-desc">Keep your account protected with several layers of authentication.</div>
      </div>

      <form className="password-form" noValidate onSubmit={handleSubmit(onSubmit)} key={formKey}>
        <div className="form-row col-2">
          <FormControl>
            <div className="label-block">{translate('forms.change_password.current_password')}</div>
            <TextField
              required
              placeholder={translate('forms.change_password.current_password')}
              id="old-password"
              type={visible.oldPassword ? 'text' : 'password'}
              {...register('oldPassword', {
                required: translate('forms.common.required') as string,
                pattern: {
                  value: OLD_PASSWORD_REGEX,
                  message: translate('forms.common.invalid_password'),
                },
                maxLength: {
                  value: 100,
                  message: translate('forms.common.max_length')
                }
              })}
              error={!!errors.oldPassword}
              sx={{'& > div': {paddingRight: '32px'}}}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IsVisibleButton
                      isPrimary
                      onClick={handleClickShowPassword('oldPassword')}
                      isNowVisible={visible.oldPassword}
                    />
                  </InputAdornment>,
              }}
            />
            {errors.oldPassword &&
              <ValidationErrorBlock errorMessage={errors.oldPassword.message as string}/>
            }
          </FormControl>
        </div>
        <div className="form-row col-2">
          <FormControl>
            <div className="label-block">{translate('forms.common.password')}</div>
            <TextField
              placeholder={translate('forms.common.password')}
              required
              id="password"
              type={visible.password ? 'text' : 'password'}
              {...register('password', {
                required: translate('forms.common.required') as string,
                maxLength: {
                  value: MAX_INPUT_VALUE_LENGTH,
                  message: translate('forms.common.max_length')
                }
              })}
              error={!!errors.password}
              sx={{'& > div': {paddingRight: '32px'}}}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IsVisibleButton
                      isPrimary
                      onClick={handleClickShowPassword('password')}
                      isNowVisible={visible.password}
                    />
                  </InputAdornment>,
              }}
            />
            {errors.password && <ValidationErrorBlock errorMessage={errors.password.message as string}/>}
            {(!!mainPass?.length) && (
              <ul className={mainPass?.length === 0 ? 'auth-password-help validation' : 'auth-password-help'}>
                <li className={mainPass?.length > 9 ? 'success' : 'error'}>
                  10 characters minimum
                </li>
                <li className={
                  !!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
                  (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0) ? 'error' : 'success'
                }>
                  No dictionary words, repetitions and commonly used passwords
                </li>
                <li className={!/\s/.test(mainPass) ? 'success' : 'error'}>
                  No spaces
                </li>
                <li className={/^(.*[0-9].*)$/.test(mainPass) ? 'success' : 'error'}>
                  One number
                </li>
                <li className={/^(?=.*[a-z])(?=.*[A-Z]).*$/.test(mainPass) ? 'success' : 'error'}>
                  At least one uppercase and one lowercase
                </li>
                <li className={/^(?=.*[@$!%*^"])[a-zA-Z0-9@$!%*^"]+$/.test(mainPass) ? 'success' : 'error'}>
                  One special character. Use only: $ ! % * ^ &quot; @
                </li>
                {(!PASSWORD_REGEX.test(mainPass) || (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "dictionary")?.length ||
                  (!!zxcvbn(mainPass)?.sequence?.filter(i => i.pattern === "repeat")?.length && zxcvbn(mainPass)?.score === 0))) && (
                  <li className={'error'}>
                    {translate('forms.common.invalid_password')}
                  </li>
                )}
              </ul>
            )}
          </FormControl>
        </div>
        <div className="form-row col-2">
          <FormControl>
            <div className="label-block">{translate('forms.common.confirm_password')}</div>
            <TextField
              placeholder={translate('forms.common.confirm_password')}
              required
              id="confirm-password"
              type={visible.confirmPassword ? 'text' : 'password'}
              {...register('confirmPassword', {
                required: translate('forms.common.required') as string,
                pattern: {
                  value: PASSWORD_REGEX,
                  message: translate('forms.common.invalid_password'),
                },
                maxLength: {
                  value: 100,
                  message: translate('forms.common.max_length')
                }
              })}
              sx={{'& > div': {paddingRight: '32px'}}}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IsVisibleButton
                      onClick={handleClickShowPassword('confirmPassword')}
                      isNowVisible={visible.confirmPassword}
                      isPrimary
                    />
                  </InputAdornment>,
              }}
              error={!!errors.confirmPassword}
            />
            {(errors?.confirmPassword || notMatchError) &&
              <ValidationErrorBlock
                errorMessage={errors?.confirmPassword?.message || translate('forms.common.passwords_dont_match')}/>
            }
          </FormControl>
        </div>
        <div className="action-row">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || notMatchError}
          >
            {translate('forms.change_password.change_password')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
