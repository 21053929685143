import {CircularProgress, Grid} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Divider, Typography} from '../../components/StyledComponents';
import {IUser} from '../../models/models';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  getUserSelector,
  githubActualPlan,
  githubTrialHoursLeft,
  gitlabActualPlan,
  gitlabTrialHoursLeft,
  isSubUser,
  jiraActualPlan,
  jiraTrialHoursLeft,
  loadingUser,
  notionActualPlan,
  notionTrialHoursLeft,
  roleByApp,
  trelloActualPlan,
  trelloTrialHoursLeft
} from '../../store/selectors';
import withRedirectAfterLogout from '../../utils/withRedirectAfterLogout';
import Stats from './Stats';
import './style.scss';
import {getDashboardActivity, getDashboardData} from "../../store/actions";
import Platform from "../../models/platforms-data";
import {EPlatformsNames} from "../../models/consts";
import {useNavigate} from "react-router-dom";
import {Plus} from "react-feather";
import {BadgesIFrameMode, BadgesNotionUpd, BadgesSubscription, BadgesTrial} from "../../components/Badges/Badges";
import {IError} from "../../models/inner-models";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../utils/popUpTextDecorator";
import {errorBigIcon} from "../../assets/icons/icons";
import {iniFrame} from "../../serviceWorker";
import {useWebSocket} from "../../contexts/WebSocketContext";
import {EWebsocketMethods} from "../../constants";
import SubInfo from "./SubInfo";
import Table from "./Table";
import DashInfoCard from "./DashInfoCards";

type ActivityServerTypes = {
  name: string,
  type: string,
  date: number,
  status: string,
  platform: string,
};

type ActivityRowTypes = ActivityServerTypes & {
  id: number;
};

const DashboardPage: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user: IUser | null = useAppSelector(getUserSelector);
  const userLoading = useAppSelector(loadingUser);
  const isUserSub = useAppSelector(isSubUser);
  const roles = useAppSelector(roleByApp);

  const platformList: Array<string> = ['github', 'trello', 'gitlab', 'jira', 'notion'];

  //Object.values(subscriptionPlans).every(Boolean) ensures all required subscriptions exist.
  const subscriptionPlans = {
    github: useAppSelector(githubActualPlan),
    trello: useAppSelector(trelloActualPlan),
    gitlab: useAppSelector(gitlabActualPlan),
    jira: useAppSelector(jiraActualPlan),
    notion: useAppSelector(notionActualPlan),
  };

  const trialHoursLeft = {
    github: useAppSelector(githubTrialHoursLeft),
    trello: useAppSelector(trelloTrialHoursLeft),
    gitlab: useAppSelector(gitlabTrialHoursLeft),
    jira: useAppSelector(jiraTrialHoursLeft),
    notion: useAppSelector(notionTrialHoursLeft),
  };
  const [activityRows, setActivityRows] = useState<Array<ActivityRowTypes>>();

  const [lastBackup, setLastBackup] = useState<string>('');
  const [itemsAmount, setItemsAmount] = useState<Record<string, number>>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadingActivity, setLoadingActivity] = useState<boolean>(true);
  const {onMessage} = useWebSocket();

  const totalAddedItems = Object.values(subscriptionPlans)
    .reduce((sum, plan) => sum + (plan?.addedItemsQuantity || 0), 0);

  const activeTrials = platformList.filter(
    (item) => trialHoursLeft[item] && subscriptionPlans[item]?.status === 'active'
  );

  const activePaid = platformList.filter(
    (item) => !trialHoursLeft[item] && subscriptionPlans[item]?.status === 'active'
  );

  //redirect if no license (new account)
  useEffect(() => {
    if (user && !user.hadEverAnySubscriptions && !userLoading) {
      if (iniFrame()) {
        navigate('app-connector?jiraInIframe=true')
      } else {
        navigate('app-connector')
      }
    }
  }, [user, userLoading])

  const transformItemsCount = (backupCountRaw) => {
    const backupCount = {}
    Object.keys(backupCountRaw).forEach((key) => {
      const item = backupCountRaw[key];
      if (['github', 'gitlab'].includes(key)) {
        backupCount[key] = {
          main: {
            total: item?.repos?.allItems || item?.projects?.allItems || 1,
            backup:  item?.repos?.backupedItems || item?.projects?.backupedItems || 0
          },
          sub: {
            total: item?.gists?.allItems || item?.snippets?.allItems || 1,
            backup:  item?.gists?.backupedItems || item?.snippets?.backupedItems || 0
          },
        };
      } else {
        backupCount[key] = {
          main: {
            total: item?.allItems || 1,
            backup: item?.backupedItems || 0
          },
        }
      }
    });
    setItemsAmount(backupCount)
  }

  useEffect(() => {
    const getDashData = async () => {
      setLoadingData(true);
      try {
        const dashData = await dispatch(getDashboardData()).unwrap();
        let platformActivity: string[] = []

        if (dashData) {
          if (dashData.platforms_items_count) {
            transformItemsCount(dashData.platforms_items_count)
            setLoadingData(false)
            platformActivity = Object.keys(dashData.platforms_items_count)
          } else {
            setItemsAmount({})
          }

          if (!!platformActivity.length) {
            getDashboardActivityScope(platformActivity)
          }
        }
      } catch (err) {
        const error = err as IError;
        Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.message || error.error as string),
          imageUrl: errorBigIcon,
        });
        console.log(err);
      } finally {
        setLoadingData(false)
      }
    }

    onMessage((data) => {
      if (data.method === EWebsocketMethods.updateDashboardPlatformItemsAmount) {
        transformItemsCount(data.platforms_items_count)
      }
    });

    getDashData()
  }, [])

  const getDashboardActivityScope = async (platformList) => {
    try {
      const activityData = await Promise.all(
        platformList.map(async (platform) => {
          const data: ActivityServerTypes[] = await dispatch(getDashboardActivity(platform)).unwrap();
          return {[platform]: data.map((item, index) => ({...item, id: index}))};
        })
      );
      const dashboardActivity: ActivityRowTypes[] = Object.assign({}, ...activityData);
      setActivityRows(dashboardActivity)
      const allDates = Object.values(dashboardActivity)
        .flat()
        .map(entry => entry.date);
      const latestDate = allDates.length > 0 ? Math.max(...allDates) : null;
      if (latestDate) {
        setLastBackup(new Date(latestDate).toLocaleString())
      }
    } catch (err) {
      const error = err as IError;
      Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.message || error.error as string),
        imageUrl: errorBigIcon,
      });
      console.log(err);
    } finally {
      setLoadingActivity(false)
    }
  }

  return (
    <div id="dashboard-page">
      {(loadingData || userLoading) ?
        <div className="spinner-wrapper">
          <CircularProgress/>
        </div> :
        <div className="header-block">
          {iniFrame() &&
            <BadgesIFrameMode/>
          }
          {!isUserSub && subscriptionPlans['notion']?.addedItemsQuantity === 1 && subscriptionPlans['notion']?.status === 'active' && (
            <BadgesNotionUpd platformName='notion'/>
          )}
          {activeTrials?.map(item => {
            const days = Number(trialHoursLeft[item]) || 0;
            if (!isUserSub || (isUserSub && roles?.[item])) {
              return <BadgesTrial key={item} trialDays={days} platformName={item} isDash={true}/>;
            }
          })
          }
          {Object.values(subscriptionPlans).every(Boolean) &&
            activePaid.map((item) => {
              const subInfo = subscriptionPlans[item];
              const show = subInfo?.isCancel && subInfo?.finishDateInMillisecond && ((subInfo.finishDateInMillisecond - Date.now()) / (24 * 60 * 60 * 1000)) < 1;
              if (show) {
                return (
                  <BadgesSubscription key={item} platformName={item} isDash={true}/>
                )
              }
            })
          }

          <Typography variant="h1" mb='32px' className='with-control'>
            {translate('common.default_pages.dashboard')}

            {!isUserSub && (
              <Button mr={2} variant="contained" color="primary"
                      onClick={() => navigate('app-connector')}
              >
                <Plus className='feather-icon-in-button'/>
                {translate('common.buttons.addPlatform')}
              </Button>
            )}
          </Typography>

          <DashInfoCard lastBackup={lastBackup} total={totalAddedItems}/>

          {user && itemsAmount &&
            Object.keys(subscriptionPlans).map((platformName: string) => {
              const item = subscriptionPlans[platformName];
              if (((isUserSub && roles?.[platformName]) || !isUserSub) && item.isConnect && itemsAmount[platformName]) {
                return (
                  <Grid container spacing={6} key={platformName}>
                    <Divider my={6} className="divider" textAlign="left">
                      <div className='dashboard-logo-block'>
                        <img src={Platform[platformName]?.smallLogo}
                             className="platformLogo" loading="lazy"
                        />
                        {Platform[platformName]?.title}
                      </div>
                    </Divider>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                      <SubInfo
                        title={Platform[platformName as EPlatformsNames]?.title || ''}
                        plan={item.plan}
                        finishDate={item.finishDate}
                        status={item.status}
                      />
                      <Stats
                        platform={platformName}
                        itemsAmount={itemsAmount[platformName]}
                        title={Platform[platformName as EPlatformsNames]?.title || ''}
                        isTrialEnd={item.status !== 'active' && (item.plan === '-' || item.plan === 'Essential' || item.plan === '')}
                        plan={item.plan}
                        isConnect={item.isConnect}
                      />
                    </Grid>
                    <Grid key={platformName} item xs={12} sm={12} md={12} lg={8} xl={8}>
                      <Table
                        title={Platform[platformName as EPlatformsNames]?.title || ''}
                        data={activityRows?.[platformName]}
                        loading={loadingActivity}/>
                    </Grid>
                  </Grid>
                )
              }
            })}

          <Grid container spacing={6}>
            <Grid item xs={12} lg={12}>
              <Typography variant="h4" display="block" className='block-head'>
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
            </Grid>
          </Grid>
        </div>
      }
    </div>
  );
};

export default withRedirectAfterLogout()(DashboardPage);
